import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Paper, Grid, Box, Typography, Stack, Link, IconButton, Button, Divider } from '@mui/material';
import { NavMenu } from './NavMenu'
import BreadCumControl from './Controls/BreadcumControl';
import EnrollmentBanner from './EnrollmentBanner';
import Footer from './Footer';
import PersonalInfoSection from './PersonalInfoSection';
import ProfileChangePassword from './ProfileChangePassword';
import ProfileRecentActivity from './ProfileRecentActivity';
import ProfilePreferences from './ProfilePreferences';
import SecuritSection from './Profile/SecuritSection';
import PostLoginMenu from './PostLoginMenu'
import { MainNavMenu } from './MainNavMenu'
import Breadcrumb from './Controls/Breadcrumb';
import Container from '@mui/material/Container';
import OverlaySpinner from './Controls/OverlaySpinner';
import { DrawerAppBar } from './AppNavbar'
//import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EnrollmentHeader from './EnrollmentHeader'
import pwdimg from '../assets/images/457.jpg';
import Image from 'material-ui-image'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { pink, grey } from '@mui/material/colors';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { GetLoggedInUserId, GetLoggedInUserEmail, CallAPI } from '../global/APIUtils';
import profimg from '../assets/images/profimg.jpg';
import ProfilePicUpdDlg from '../dialogs/ProfilePicUpdDlg';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import AlertControl from './Controls/AlertControl';
import { APICall, WriteToActivityLog } from '../global/Api';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import AutoLagoutTimer from '../global/IdleMonitor';
import { ActivityLogEvent } from '../common/AOBEnum';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const UserProfileSetting = (props) => {

    let sections = {
        personalInfo: 1,
        changePassword: 2,
        recentActivity: 3,
        preferences: 4
    };
    const location = useLocation();
    /*const { cntCtId, emailAdrs } = route.params;*/
    //const cntCtId = useRef(location.state.userId || -1);
    const emailAdrs = useRef(null);
    //const userNm = useRef(null);
    //const selectedSection = useRef(sections.personalInfo);

    //const [cntCtId] = useState(location.state.CntCtId);
    //const [emailAdrs] = useState(location.state.EmailAdrs);
    const [selectedSection, setSelectedSection] = useState(sections.personalInfo);
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [userNm, setUserNm] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [showImageDlg, setShowImageDlg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    let sessions = useSelector((state) => state.sessionMgmnt);

    const loadScreen = async () => {

        try {

            setIsLoading(true);

            let apiImageUrl = new URL(
                process.env.REACT_APP_GET_PROFILE_IMAGE,
                process.env.REACT_APP_BASE_URL,
            );

            apiImageUrl.searchParams.set('cntctId', `${parseInt(userId)}`);

            let response = await APICall({ url: apiImageUrl.href, method: 'GET', contentType: 'application/json', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }

                await WriteToActivityLog(ActivityLogEvent.Template, "Opened profile information page.", userId, sessionId);

                setIsLoading(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }
    }

    useEffect(() => {

        emailAdrs.current = GetLoggedInUserEmail(userId, sessionId);

        loadScreen();

    }, []);

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    const onClickHandlePic = (event) => {
        event.preventDefault();
        setShowImageDlg(true);
    }
    const closeImageDlg = () => {
        //event.preventDefault();
        setShowImageDlg(false);
    }

    const onPicChangeHandle = async () => {
        setShowImageDlg(false);

        try {

            setIsLoading(true);

            let apiImageUrl = new URL(
                process.env.REACT_APP_GET_PROFILE_IMAGE,
                process.env.REACT_APP_BASE_URL,
            );

            apiImageUrl.searchParams.set('cntctId', `${parseInt(userId)}`);

            let response = await APICall({ url: apiImageUrl.href, method: 'GET', contentType: 'application/json', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }
                setIsLoading(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }
    }

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="" disableGutters={true}>
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid" sx={{}}>
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead">
                        <Box sx={{
                            pr: 0,
                            pl: 0, pt: 0
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 2 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" sx={{ pt: 0, pb: 0, }}>
                        <Grid item md={12} sx={{ pr: 0 }}>
                            <Box sx={{ pl: 0, pt: 0 }}>
                                <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 9, mr: 0, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                    <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, fontSize: '20px', pb: 0, letterSpacing: '0px' }} >Profile information</Typography>
                                </Box>
                                <Box sx={{ ml: 0, mr: 0, }}>
                                    <Divider variant="fullWidth" component="div" />
                                </Box>
                                <Box sx={{ mt: 0, pt: 2.5, pb: 2, ml: 9, mr: 40, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                    <Stack direction="column" sx={{ }}>
                                        <Typography sx={{ pl: 0, fontWeight: 400, }} variant="body1" color="text.primary">
                                            Manage your personal and security information to make the application work better for you. This dashboard provides access to your account information.
                                        </Typography>
                                        <Typography sx={{ pt: 2, fontWeight: 400, }} variant="body1" color="text.primary">
                                            You can manage settings for your account at any time. Update your contact information, change your password or change your 2-step verification setting.
                                        </Typography>
                                    </Stack>
                                </Box>
                                <Box sx={{ ml: 6 }}>
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                </Box>
                            </Box>
                        </Grid>
                        {/*<Grid item md={3}>*/}
                        {/*    <Grid container>*/}
                        {/*        <Grid item md={12} sx={{ p: 1 }} >*/}
                        {/*            <Box className="" sx={{ display: 'flex', justifyContent: 'flex-start', pt: 0, pl: 2, ml: 4, }}>*/}
                        {/*                <Stack direction="column">*/}
                        {/*                    {imageUrl ?*/}
                        {/*                        <img style={{ cursor: 'pointer', }} onClick={onClickHandlePic} src={URL.createObjectURL(imageUrl)} alt="Prifile Picture" className="prof-pic-prvw" />*/}
                        {/*                        :*/}
                        {/*                        <img style={{ cursor: 'pointer', }} onClick={onClickHandlePic} src={profimg} alt="Prifile Picture" className="prof-pic-prvw" />*/}
                        {/*                    }*/}
                        {/*                    <Typography variant="caption" color="text.primary" sx={{ ml: 0,mt: 2, fontWeight: 400, }}>*/}
                        {/*                        {emailAdrs.current}*/}
                        {/*                    </Typography>*/}
                        {/*                </Stack>*/}
                        {/*        </Box>*/}
                        {/*        </Grid>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Grid item xs={12} sx={{pl: 0}}>
                        <Grid item xs container direction="row" sx={{pt:0, pb: 4}}>
                            <Grid item xs={12} md={3} sx={{}}>
                                <Box sx={{ ml: 7, mr: 2, minHeight: '600px', border: 1, borderColor: '#e1e3e1'}}>
                                    <Stack direction="column">
                                        <Box sx={{ mt: 1.5, mb: 1, pl: 0 }} >
                                            <Button sx={{}} onClick={() => setSelectedSection(sections.personalInfo)}>
                                                <Typography variant="body1" sx={{ textTransform: 'none', ml: 2, fontWeight: 400 }} color="text.primary">Personal Info</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ mt: 0.5, mb: 0,}} />
                                        <Box sx={{ mt: 1, mr: 4, pl: 0, }} >
                                            <Button sx={{ mr: 0, }} onClick={() => setSelectedSection(sections.changePassword)}>
                                                <Typography variant="body1" sx={{ textTransform: 'none', ml: 2, fontWeight: 400 }} color="text.primary">Change Password</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ mt: 1.5, mb: 0 }} />
                                        <Box sx={{ mt: 1, mr: 4, pl: 0 }} >
                                            <Button sx={{ mr: 0, }} onClick={() => setSelectedSection(sections.preferences)}>
                                                <Typography variant="body1" sx={{ textTransform: 'none', ml: 2, fontWeight: 400 }} color="text.primary">Preferences</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ mt: 2, mb: 0  }} />
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ }}>
                                    {
                                        (selectedSection === sections.personalInfo) ?
                                            <PersonalInfoSection userId={userId} sessionId={sessionId} disablerole={true} isadmin={false} />
                                            :
                                            (selectedSection === sections.changePassword) ?
                                                <ProfileChangePassword userId={userId} sessionId={sessionId} emailadrs={emailAdrs.current} />
                                                :
                                                (selectedSection === sections.recentActivity) ?
                                                    <ProfileRecentActivity cntctid={userId} emailadrs={emailAdrs.current} />
                                                    :
                                                    (selectedSection === sections.preferences) ?
                                                        <ProfilePreferences userId={userId} sessionId={sessionId} emailadrs={emailAdrs.current} />
                                                    :
                                                <div><h5>Invalid operation!!</h5></div>

                                    }
                            </Grid>
                            <Grid item md={3}>
                                <Grid container>
                                    <Grid item md={12} sx={{ p: 1 }} >
                                        <Box className="" sx={{ display: 'flex', justifyContent: 'flex-start', pt: 0, pl: 1, ml: 4, }}>
                                            <Stack direction="column">
                                                {imageUrl ?
                                                    <img style={{ cursor: 'pointer', }} onClick={onClickHandlePic} src={URL.createObjectURL(imageUrl)} alt="Prifile Picture" className="prof-pic-prvw" />
                                                    :
                                                    <img style={{ cursor: 'pointer', }} onClick={onClickHandlePic} src={profimg} alt="Prifile Picture" className="prof-pic-prvw" />
                                                }
                                                <Typography variant="body2" color="text.primary" sx={{ ml: 0, mt: 1.5, fontWeight: 400, }}>
                                                    {emailAdrs.current}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Footer userId={userId} />
                    {showImageDlg ?
                        <ProfilePicUpdDlg open={showImageDlg} onClose={closeImageDlg} onChange={onPicChangeHandle} cntctid={userId} userId={userId} sessionId={sessionId} />
                        : null
                    }
                </Paper>
            </Container>
        </React.Fragment>
    );

}

function UserProfileBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>
                <Typography variant="h5" color="text.primary" sx={{ lineHeight: 1, fontWeight: 400, letterSpacing: '-1px' }} >Profile Information</Typography>
            </Box>
        </React.Fragment>
    );
}

export default UserProfileSetting;