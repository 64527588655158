import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, Box, FormGroup, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
import { CallAPIUsingAxios, IsAxiosError } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { APICall, WriteToActivityLog } from '../global/Api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ActivityLogEvent } from '../common/AOBEnum';
import { Stack } from '@mui/material';

LoadingOverlay.propTypes = undefined;

const AppHistoryDlg = (props) => {

    const { onClose, open, enrmntid, enrmntNm } = props;
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const handleOk = async (event) => {
        onClose();
    };

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];

    const loadAppHist = async () => {
        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_APP_HISTORY,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('enrmntNm', enrmntNm);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);
                setData(response.data[0]);
                await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Viewed application history. Application name: '" + enrmntNm + "'.", userId, sessionId);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {

        }
    }

    useEffect(() => {
        loadAppHist();
    }, []);

    return (
        //<Dialog sx={{ '& .MuiDialog-paper': { width: '100%' } }} onClose={handleOk} open={open} keepMounted maxWidth="xs" >

            <Dialog onClose={handleOk} 
                open={open}
                aria-labelledby="draggable-dialog-title"
                keepMounted
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "600px",
                        },
                    },
                }}
            >
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle>

                    <Box sx={{ pl: 1.5, pt: 1 }}>
                        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                            Application Status History
                        </Typography>
                    </Box>
                    {/*<Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>*/}
                    {/*    Application Status History*/}
                    {/*</Typography>*/}
                    
                    <IconButton
                        aria-label="close"
                        onClick={handleOk}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 12,
                            color: (theme) => theme.palette.Icon.main,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Grid container>
                        <Grid item md={11} sx={{}}>
                            <Box sx={{pl: 4, pb: 3.5}}>
                                <TableContainer component={Paper} sx={{ maxHeight: '400px', background: 'transparent', border: 1, borderColor: 'divider' }}>
                                    <Table aria-label="simple table">

                                        <TableBody>
                                            {
                                                (data != null) ? data.map((row) => (
                                                <TableRow
                                                    key={row.Id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                        <TableCell component="th" scope="row">
                                                            <Stack direction="column">
                                                                <Typography className="" variant="body1" sx={{ fontWeight: 500, }} color="text.primary">{row.enrmntStatus}</Typography>
                                                                <Typography className="" variant="body2" sx={{ fontWeight: 400, pt: 0.5}} color="text.primary">{row.lastModifiedOn}</Typography>
                                                                {
                                                                    row.stsReason ? 
                                                                        <Typography className="" variant="body2" sx={{ fontWeight: 400, pt: 1 }} color="text.primary"><b>Failed reason:</b> {row.stsReason}</Typography>
                                                                        : null
                                                                }
                                                            </Stack>
                                                    </TableCell>
                                                </TableRow>
                                                ))
                                                    : <TableRow
                                                        key={0}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            No Data
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 3.5, pb: 1.5 }}>
                    <Button variant="contained" onClick={handleOk} sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.6rem', textTransform: 'none'}} >
                        Ok
                    </Button>
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default AppHistoryDlg;
