
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';
import { Stack } from '@mui/material';

export function ConfirmDialog(props) {
    const { onClose, params, open, ...other } = props;

    const handleCancel = () => {
        onClose(null, null);
    };

    const handleOk = () => {
        onClose(props.nvalue, props.svalue);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%'} }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle sx={{ml: 0, mr: 2}}>                
                <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                    {props.alertheader}
                </Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ ml: 0, mr: 2, }}>
                <Box sx={{pb: 2, pl: 1}}>
                    <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline1}</Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ pr: 1.5, pb: 2 }}>
                <Box sx={{ pt: 1.5 }}>
                    <Stack direction="row" spacing={0.5}>
                        <Box sx={{ }}>
                            <Button autoFocus variant="contained" onClick={handleCancel} size="small" sx={{ mr: 1, borderRadius: '20px', padding: '0.25rem 2.6rem', textTransform: 'none' }}>
                                No
                            </Button>
                            </Box>
                        <Box sx={{ pr: 1 }}>
                            <Button variant="contained" onClick={handleOk} size="small" sx={{ mb: 1, borderRadius: '20px', padding: '0.25rem 2.6rem', textTransform: 'none' }}>
                                Yes
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
