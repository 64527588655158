import { Power } from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import logo from '../assets/images/BankLogo-circle.png'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { deleteSession } from '../redux/sessionMgmnt';
import { Logout } from '../global/Logout';
import { Button, Link, Typography, Paper, Box, Stack, CardMedia } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import aoblogoimg from '../assets/images/aelogo.jpg';
//import clientlogo from '../assets/images/ssb-logo1.jpg';
//import clientlogo from '../assets/images/bank_logo.jpg';
//import clientlogo from '../assets/images/demo_bank_logo.jpg';
import { ServerAPICall } from '../global/Api';
import { ErrorMessages } from '../common/ErrorMessages';

const EnrollmentHeader = () => {

    const [imageUrl, setImageUrl] = useState(null);

    const loadLoginHeroImage = async () => {
        try {

            //setIsLoading(true);

            let apiImageUrl = new URL(
                process.env.REACT_APP_GET_BANK_RESOURCE,
                process.env.REACT_APP_BASE_URL,
            );

            apiImageUrl.searchParams.set('resourceType', 1);

            let response = await ServerAPICall({ url: apiImageUrl.href, method: 'GET', contentType: 'application/json', responseType: 'blob' });

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }
            }
        }
        catch (err) {
            console.error(ErrorMessages.UnknownError);
            //setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            //setIsLoading(false);
        }
    };

    useEffect(() => {
        loadLoginHeroImage();
    }, []);

    return (
        <React.Fragment>
            <Toolbar disableGutters={true} sx={{ borderBottom: '0px solid #e0e0e0', justifyContent: 'space-between', pb: 0.5, pt: 1, boxShadow: '0 2px 2px 0 #9EA0A9' }}>
                <Box sx={{ pl: 7.5,  }}>
                    <Stack direction="column">
                        <Typography variant="h5" color="text.primary" sx={{ pl: 1, fontWeight: 500, textTransform: 'none', lineheight: 0.9, letterSpacing: '0px' }}>Digital Account Onboarding</Typography>
                        {/*<Typography variant="caption" color="text.secondary" sx={{ pl: 4, pt: 0, pb: 1, fontWeight: 400, letterSpacing: '0px', lineheight: 0.9 }}>Open account online instantly.</Typography>*/}
                    </Stack>
                </Box>
                {/*<Box sx={{ pl: 4 }}>*/}
                {/*    <img src={aoblogoimg} height="44" loading="lazy" />*/}
                {/*</Box>*/}
                <Box sx={{ pr: 8, pb: 1 }}>
                    {imageUrl ?
                        <img src={URL.createObjectURL(imageUrl)} height="60" loading="lazy" />
                        :
                        null
                    }
                    {/*<img src={clientlogo} height="39" loading="lazy" />*/}
                </Box>
            </Toolbar>

        </React.Fragment>
    );
};

export default EnrollmentHeader;