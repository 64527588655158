import React, { useEffect, useState, useRef } from 'react';
import { Divider, Typography, InputBase, IconButton, Toolbar, Grid, Stack, FormControl, Box, FormGroup, FormControlLabel, InputLabel, Select, MenuItem, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { CallAPIUsingAxios } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { GetApplicantImage } from '../global/EnrollmentHelper';
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';




const TemplateNotAvailableInGWESDlg = (props) => {

    const [data, setData] = useState(null);
    const { onClose, open, onChange } = props;
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [templateId, setTemplateId] = useState(props.templateId);
    
    const [recordCount, setRecordCount] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    
    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    function EnhancedTableToolbar(props) {
        const { numSelected, data } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                {
                    data && data.length == 0 ? <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }} >No record found</Typography>
                        :
                        numSelected > 0 ? (
                            <Stack direction="row" spacing={2}>
                                <Typography variant="body1">
                                    <strong>{props.message}</strong>
                                </Typography>
                                <Typography variant="body1">
                                    {numSelected} selected
                                </Typography>
                            </Stack>
                        ) : (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="body1"
                                id="tableTitle"
                                component="div"
                            >
                                {props.message}
                            </Typography>
                        )}
            </Toolbar>
        );
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, numSelected, rowCount, headCells } = props;

        return (
            <TableHead>
                <TableRow>                    
                    {
                        headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                            >
                                <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 500 }} >{headCell.label}</Typography>
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
        );
    }
    
    const controlsHeadCells = [
        {
            id: '1',
            numeric: false,
            disablePadding: false,
            label: 'Label',
        },
        {
            id: '2',
            numeric: false,
            disablePadding: false,
            label: 'Control Type',
        },
        {
            id: '3',
            numeric: false,
            disablePadding: false,
            label: 'Control Name',
        }
    ];
    
    const loadForm = async () => {

        try {

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_TEMPLATE_NO_MAPPING_CONTROLS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('templateId', `${templateId}`);
           
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== undefined && response !== null) {

                setRecordCount(response.data.length);

                setData(response.data);

                setPage(0);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    const visibleRows = React.useMemo(
        () =>
            data && data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [data, page, rowsPerPage],
    );
    
    useEffect(() => {

        loadForm();

    }, []);

    return (
        <Dialog maxWidth='md' onClose={handleClose} open={open} keepMounted>
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle>

                    <Box sx={{ pl: 1.5, pt: 1 }}>
                        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                            Control(s) not available in GWES
                        </Typography>
                    </Box>

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 10,
                            color: (theme) => theme.palette.Icon.main,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ pb: 0.5, ml: 2, mr: 2 }}>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%', mt: 0 }}>
                                <Paper sx={{ width: '100%', mb: 2 }}>
                                    <EnhancedTableToolbar numSelected={selected.length} message={'Controls'} data={data} />
                                    <TableContainer sx={{ height: 400 }}>
                                        <Table
                                            sx={{ minWidth: 750, maxHeight: 400 }}
                                            aria-labelledby="tableTitle"
                                            size={'medium'}
                                        >
                                            <EnhancedTableHead headCells={controlsHeadCells}
                                                rowCount={data !== null ? data.length : 0}
                                            />
                                            <TableBody>
                                                {visibleRows && visibleRows.map((row, index) => {
                                                    /*const isItemSelected = isSelected(row.LookUpSeqId);*/
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover 
                                                            tabIndex={-1}
                                                            key={index}
                                                            sx={{ cursor: 'pointer' }}
                                                        >  
                                                            <TableCell sx={{ width: 150 }}>
                                                                <Typography variant="body1" color={row.IsTypeChanged ? '#f00' : 'text.secondary'} sx={{ fontWeight: 400 }} >{row.FieldLabel}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ width: 100 }}>
                                                                <Typography variant="body1" color={row.IsTypeChanged ? '#f00' : 'text.secondary'} sx={{ fontWeight: 400 }} >
                                                                    {
                                                                        row.ControlType === 'checkbox-group' ? 'Checkbox Group' :
                                                                            row.ControlType === 'radio-group' ? 'Radio Group' :
                                                                                row.ControlType === 'select' ? 'Select' :
                                                                                    row.ControlType === 'textarea' ? 'Text Area' :
                                                                                        row.ControlType === 'date' ? 'Date Field' :
                                                                                            row.ControlType === 'number' ? 'Number' : 'Text Field'
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ width: 150 }} >
                                                                <Typography variant="body1" color={row.IsTypeChanged ? '#f00' : 'text.secondary'} sx={{ fontWeight: 400 }} >{row.ControlNm}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 75]}
                                        component="div"
                                        count={recordCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        sx={{
                                            ".MuiTablePagination-selectLabel, .MuiTablePagination-toolbar": {
                                                paddingTop: "8px"
                                            },
                                            ".MuiTablePagination-displayedRows": {
                                                paddingTop: "8px", fontSize: '15px'
                                            },
                                        }}
                                        classes={{
                                            //toolbar: classes.toolbar,
                                        }}
                                    />
                                </Paper>
                            </Box>
                        </Grid>

                    </Box>
                </DialogContent>
            </LoadingOverlay>
        </Dialog>
    );
}

export default TemplateNotAvailableInGWESDlg;
