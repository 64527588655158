import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu'
import OverlaySpinner from './Controls/OverlaySpinner';
import Footer from './Footer';
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { CallAPI, IsInRole } from '../global/APIUtils';
import { Virtualizer } from '@tanstack/react-virtual';
import { Grid, Stack, Paper, IconButton, Box, Tooltip, Menu, Typography, Divider, MenuItem, InputBase, Card, CardContent, CardMedia, bull, CardActions, Button } from '@mui/material';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { IsError } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AlertDialog } from '../global/AlertDialog';
import AlertWithTitle from './Controls/AlertWithTitle';
import AlertControl from './Controls/AlertControl';
import { APICall, WriteToActivityLog } from '../global/Api';
import AutoLagoutTimer from '../global/IdleMonitor';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { ActivityLogEvent, AOBSystemProcess } from '../common/AOBEnum';

const ManageRole = (props) => {

    const location = useLocation();

    const [userCount, setUserCount] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [rowSelection, setRowSelection] = useState({});
    const [disEditApp, setDisEditApp] = useState(true);
    const [roles, setRoles] = useState(null);
    const [enableDelBtn, setEnableDelBtn] = useState(true);
    const [enableEditBtn, setEnableEditBtn] = useState(true);
    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const tableInstanceRef = useRef(null);
    const [expanded, setExpanded] = useState(true);
    const [roleName, setRoleName] = useState(null);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');
    const confirmDlgBLine2 = useRef('');
    const confirmDlgBLine3 = useRef('');
    const confirmDlgBLine4 = useRef('');
    const confirmDlgBLine5 = useRef('');
    const selectedRoleTypId = useRef(0);

    let navigate = useNavigate();

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };

    const columns = [
        {
            accessorFn: (row) => row.RoleName,
            header: 'Role Name',
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body1,
                    borderLeft: 1,
                    borderBottom: 2,
                    borderColor: 'divider',
                    pl: 2, fontWeight: 400, textAlign: 'center', pt: 2, pb: 2,
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.secondary',
                    fontSize: theme.typography.body2,
                    borderTop: 2,
                    borderBottom: 1,
                    borderLeft: 1,
                    borderColor: 'divider',
                    fontWeight: 400,
                    pl: 2, pr: 2,
                    pt: 1.5, pb: 1.5,
                    fontStyle: 'italic'
                }),
            }),
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorFn: (row) => row.RoleDesc,
            header: 'Role Description',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorFn: (row) => row.IsAllPrcs,
            header: 'All Process',
            Cell: ({ cell, row }) =>
                <React.Fragment>
                    {row.original.IsAllPrcs ? 'Yes' : 'No'}
                </React.Fragment>
            ,
            size: 200
        },
        {
            accessorFn: (row) => row.IsProspect,
            header: 'Guest Only',
            Cell: ({ cell, row }) =>
                <React.Fragment>
                    {row.original.IsProspect ? 'Yes' : 'No'}
                </React.Fragment>
            ,
            size: 200
        },
        {
            accessorFn: (row) => row.IsSystem,
            header: 'System Controlled',
            Cell: ({ cell, row }) =>
                <React.Fragment>
                    {row.original.IsSystem ? 'Yes' : 'No'}
                </React.Fragment>
            ,
            size: 200
        },
        {
            accessorFn: (row) => row.CreateUser,
            header: 'Created By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 260
        },
        {
            accessorFn: (row) => row.ModUser,
            header: 'Last Modified By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 260
        },
        {
            accessorFn: (row) => row.CreateDate,
            header: 'Created At',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 260
        },
        {
            accessorFn: (row) => row.ModDate,
            header: 'Modified At',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 260
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data'],
            queryFn: async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_ROLES,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, []);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

        loadForm();

    }, []);

    const loadForm = async () => {
        await WriteToActivityLog(ActivityLogEvent.RoleManagement, "List of roles viewed.", userId, sessionId);
    };

    const HandleRowClick = async (row) => {

        tableInstanceRef.resetRowSelection = true;
        //selTempltId.current = row.original.TemplateId;

        setUserCount(row.original.UserCount + ' users assigned');
        setRoleName(row.original.RoleName);

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
        let canDelete = IsInRole(AOBSystemProcess.RoleMaintenance, userId, sessionId) || isAdmin;;

        if (row.original.UserCount > 0 || row.original.IsSystem == 1)
            setEnableDelBtn(true);
        else
            setEnableDelBtn(false);

        if (row.original.IsSystem == 1)
            setEnableEditBtn(true);
        else
            setEnableEditBtn(false);
    }

    const userCountClick = (event) => {
        //setSortMnuAnchorEl(event.currentTarget);
    };

    const newRole = (event) => {
        navigate('/RoleDefinition', { state: { roleTypId: 0, userId: userId, sessionId: sessionId }, replace: true })
        //setSortMnuAnchorEl(event.currentTarget);
    };

    const DeleteRole = async (roleTypId) => {

        const apiUrl = new URL(
            process.env.REACT_APP_DELETE_USER_ROLE,
            process.env.REACT_APP_BASE_URL,
        );

        let roleType = {
            "RoleTypeId": roleTypId
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        try {

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: roleType }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: roleType, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response != null && response != undefined) {
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    await WriteToActivityLog(ActivityLogEvent.RoleManagement, "Deleted role. Role name: '" + roleName + "'", userId, sessionId);
                    setSuccessMsg("Role deleted successfully.");
                    refetch();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
        }
    };

    const handleDeleteClick = (table) => {

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedRoleTypId.current = row.original.RoleTypeId;
            confirmDlgBLine1.current = "Do you want to delete '" + row.original.RoleName + "' role?";
            confirmDlgBLine2.current = '';
            confirmDlgBLine3.current = '';
            confirmDlgBLine4.current = '';
        }
        confirmDlgHdr.current = 'Delete Role';
        setOpenConfirmDlg(true);
    };

    const handleConfirmDialogClose = (nvalue, svalue) => {
        setOpenConfirmDlg(false);
        if (nvalue) {
            //DeleteRole(nvalue, null);
            DeleteRole(nvalue);
        }
    };

    const handleEditClick = (table) => {
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            //navigate('/RoleDefinition', { state: { roleTypId: row.original.RoleTypeId } });
            navigate('/RoleDefinition', { state: { roleTypId: row.original.RoleTypeId, roleName: row.original.RoleName, roleDesc: row.original.RoleDesc, isAllPrcs: row.original.IsAllPrcs, isProspect: row.original.IsProspect, isSystem: row.original.IsSystem, userId: userId, sessionId: sessionId }, replace: true })
        }
    };

    const handleViewClick = (table) => {
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            //navigate('/RoleDefinition', { state: { roleTypId: row.original.RoleTypeId } });
            navigate('/RoleDefinition', { state: { roleTypId: row.original.RoleTypeId, roleName: row.original.RoleName, roleDesc: row.original.RoleDesc, isAllPrcs: row.original.IsAllPrcs, isProspect: row.original.IsProspect, isSystem: row.original.IsSystem, readOnly: true, userId: userId, sessionId: sessionId }, replace: true })
            navigate('/RoleDefinition', { state: { roleTypId: row.original.RoleTypeId, roleName: row.original.RoleName, roleDesc: row.original.RoleDesc, isAllPrcs: row.original.IsAllPrcs, isProspect: row.original.IsProspect, isSystem: row.original.IsSystem, readOnly: true, userId: userId, sessionId: sessionId }, replace: true })
        }
    };

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="" disableGutters={true}>
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0,
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 0 }}>
                        <Grid item md={12} sx={{}}>
                            <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 9, mr: 9, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                <Typography variant="h6" color="text.primary" sx={{ fontSize: '20px', pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Role Management</Typography>
                            </Box>
                            <Box sx={{ ml: 0, mr: 0, }}>
                                <Divider variant="fullWidth" component="div" sx={{ }} />
                            </Box>
                            <Box sx={{ mt: 0, pt: 2.5, pb: 2, ml: 9, mr: 9, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                <Stack direction="column" sx={{}}>
                                    <Typography sx={{ pl: 0, fontWeight: 400, }} variant="body1" color="text.primary">
                                        The application allows you to assign roles to users. Each role has a set of permissions associated with it. By assigning users to different roles you can control what those users are allowed to do.
                                        In this screen you can create and manage roles in the system.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item md={12} sx={{}}>
                            <Box sx={{ pl: 0, mt: 1, ml: 9 }}>
                                <Button variant="contained" size="large" style={{ textTransform: 'none', borderRadius: '20px', padding: '0.3rem 1.9rem', }} onClick={(e) => newRole(e)} >
                                    New Role
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item xs container direction="row" spacing={0} sx={{ pt: 0, pb: 3, }}>
                            <Grid item md={12} sx={{}} justifyContent="center">
                                <Box sx={{ ml: 6, mr: 6 }}>
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                    <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                </Box>
                                {/*<Box sx={{ }}>*/}
                                {/*    <Divider variant="fullWidth" component="div" sx={{ }} />*/}
                                {/*</Box>*/}
                                <Box sx={{ borderTop: '0px solid #9FA4A8', pb: 2, ml: 9, mr: 9, pt: 2 }}>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={flatData}
                                        displayColumnDefOptions={{
                                            'mrt-row-select': {
                                                maxSize: 12,
                                                header: '',
                                            },
                                            'mrt-row-actions': {
                                                header: '',
                                                maxSize: 30,
                                            },
                                        }}
                                        muiTablePaperProps={{
                                            sx: { border: '1px solid #000' },
                                        }}
                                        enableColumnActions={false}
                                        enableColumnFilters={false}
                                        enableSorting={false}
                                        enablePagination={false}
                                        enableRowNumbers={false}
                                        enableRowVirtualization
                                        enableHiding={false}
                                        enableColumnOrdering={false}
                                        muiTableBodyRowProps={({ row }) => ({
                                            onClick: () => {
                                                setRowSelection((prev) => ({
                                                    [row.id]: true,
                                                }));
                                                HandleRowClick(row);
                                            },
                                            sx: {
                                                cursor: 'pointer',
                                            },
                                        })}
                                        positionActionsColumn="last"
                                        enableColumnResizing={false}
                                        enableDensityToggle={false}
                                        enableFullScreenToggle={false}
                                        muiTableBodyCellProps={({ column }) => ({
                                            sx: (theme) => ({
                                                color: 'text.primary',
                                                fontSize: theme.typography.body1,
                                                borderBottom: 2,
                                                borderColor: 'divider',
                                                pl: 2, fontWeight: 400, textAlign: 'center', pt: 2, pb: 2,
                                            }),
                                        })
                                        }

                                        muiTableHeadCellProps={{
                                            sx: (theme) => ({
                                                color: 'text.secondary',
                                                fontSize: theme.typography.body2,
                                                borderTop: 2,
                                                borderBottom: 1,
                                                borderColor: 'divider',
                                                fontWeight: 400,
                                                pl: 2, pr: 2,
                                                pt: 1.5, pb: 1.5,
                                                fontStyle: 'italic'
                                            }),
                                        }}

                                        muiTableContainerProps={{
                                            ref: tableContainerRef,
                                            sx: { maxHeight: '550px', minHeight: '450px' },
                                            onScroll: (
                                                event,
                                            ) => fetchMoreOnBottomReached(event.target),
                                        }}
                                        muiToolbarAlertBannerProps={
                                            isError
                                                ? {
                                                    color: 'error',
                                                    children: ErrorMessages.UnknownError,
                                                }
                                                : undefined
                                        }
                                        renderBottomToolbarCustomActions={() => (
                                            <Box sx={{ mt: 0.5, mb: 0 }}>
                                                <Typography variant="caption" color="text.blueText" sx={{ pl: 1 }}>
                                                    {
                                                        !isError && data
                                                            ? totalFetched + ' of ' + totalDBRowCount + ' role(s).'
                                                            : ''
                                                    }
                                                </Typography>
                                            </Box>
                                        )}
                                        onRowSelectionChange={setRowSelection}
                                        state={{
                                            isLoading,
                                            showAlertBanner: isError,
                                            showProgressBars: isFetching,
                                            rowSelection,
                                        }}
                                        initialState={{
                                        }}
                                        muiTablePaperProps={{
                                            elevation: 0,
                                        }}
                                        rowVirtualizerProps={{ overscan: 1 }}
                                        localization={{
                                            noRecordsToDisplay: 'No roles to display',
                                            selectedCountOfRowCountRowsSelected: '',
                                        }}
                                        renderTopToolbarCustomActions={({ table }) => {
                                            return (
                                                <Box sx={{
                                                    display: 'flex', alignItems: 'center', flexDirection: 'row'
                                                }}>
                                                    <Typography variant="body1" color="text.blueText" sx={{ pt: 0, pr: 2, fontWeight: 500, pl: 0 }}>Existing Roles</Typography>

                                                    {/*<Typography variant="body3" color="text.primary" sx={{ pl: 0, pr: 4, fontWeight: 500, letterSpacing: '-1px' }}>Existing roles</Typography>*/}
                                                    <Button type="button" size="small" sx={{}} onClick={(e) => userCountClick(e)}>
                                                        <Typography variant="body4" color="text.primary" sx={{ textTransform: 'none', fontWeight: 400 }} >{userCount}</Typography>
                                                    </Button>
                                                </Box>
                                            );
                                        }}
                                        renderToolbarInternalActions={({ table }) => {
                                            return (
                                                <Box>
                                                    <Tooltip arrow title="View role">
                                                        <span>
                                                            <IconButton sx={{}} disabled={table.getSelectedRowModel().flatRows.length <= 0} onClick={() => handleViewClick(table)}>
                                                                <ArticleOutlinedIcon width="32px" height="32px" color="#ccc" />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip arrow title="Edit role">
                                                        <span>
                                                            <IconButton sx={{}} disabled={enableEditBtn} onClick={() => handleEditClick(table)}>
                                                                <EditIcon width="24px" height="24px" color="#ccc" />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip arrow title="Delete role">
                                                        <span>
                                                            <IconButton disabled={enableDelBtn} onClick={() => handleDeleteClick(table)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                            );
                                        }}
                                        tableInstanceRef={tableInstanceRef}
                                    />
                                </Box>
                                {/*</Stack>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Footer userId={userId} />
                    {
                        openConfirmDlg ?
                            <AlertDialog
                                id="ringtone-menu"
                                keepMounted
                                open={openConfirmDlg}
                                onClose={handleConfirmDialogClose}
                                nvalue={selectedRoleTypId.current}
                                alertheader={confirmDlgHdr.current}
                                bmsgline1={confirmDlgBLine1.current}
                                bmsgline2={confirmDlgBLine2.current}
                                bmsgline3={confirmDlgBLine3.current}
                                bmsgline4={confirmDlgBLine4.current}
                            /> : ''
                    }

                </Paper>
            </Container>
        </React.Fragment>


        //<React.Fragment>
        //    <Container maxWidth="xl" sx={{}}>
        //        <PostLoginMenu userId={userId} sessionId={sessionId} />
        //        <Paper className=""
        //            sx={{
        //                position: 'relative',
        //                backgroundColor: '#fff',
        //                color: '#fff',
        //                backgroundSize: 'cover',
        //                backgroundRepeat: 'no-repeat',
        //                backgroundPosition: 'center',
        //                pb: 12
        //            }}
        //        >
        //            <Grid item>
        //                <Box sx={{
        //                    pr: { xs: 3, md: 2 },
        //                    pl: { xs: 3, md: 2 }
        //                }}>
        //                    <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
        //                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
        //                        </AccordionSummary>
        //                        <AccordionDetails sx={{ pr: 3, pl: 3, pb: 0 }}>
        //                            <EnrollmentHeader />
        //                            <DrawerAppBar userId={userId} sessionId={sessionId} />
        //                        </AccordionDetails>
        //                        <Box sx={{ pr: 4, pl: 4 }}>
        //                            <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 0 }}>
        //                                <Grid item md={9} sx={{ pr: 10 }} justifyContent="center">
        //                                    <Box sx={{ pt: 0, pr: 2, ml: 2 }}>
        //                                        <RoleListBreadcrumb />
        //                                        <Typography sx={{ ml: 1, mr: 1, mb: 3, mt: 3, fontWeight: 500 }} variant="h6" color="text.secondary">
        //                                            The application allows you to assign roles to users. Each role has a set of permissions associated with it. By assigning users to different roles you can control what those users are allowed to do.
        //                                            In this screen you can create and manage roles in the system.
        //                                        </Typography>
        //                                    </Box>
        //                                    <Box sx={{ pl: 1, mt: 3, ml: 2 }}>
        //                                        <Button variant="outlined" size="small" sx={{ padding: '0.4rem 1.7rem', border: 2, borderColor: '#002d72' }} onClick={(e) => newRole(e)} >
        //                                            <Typography variant="body2" color="#000" sx={{ textTransform: 'none', fontWeight: 500 }} >New role</Typography>
        //                                        </Button>
        //                                    </Box>
        //                                </Grid>
        //                            </Grid>
        //                        </Box>
        //                    </Accordion>
        //                </Box>
        //            </Grid>
        //            <Grid item>
        //                <Grid item xs container direction="row" spacing={2} sx={{ pt: 3, pb: 3, pl: 9, pr: 7 }}>
        //                    <Grid item md={12} sx={{}} justifyContent="center">                                    
        //                            <Box>
        //                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
        //                                <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
        //                            </Box>
        //                            <Box sx={{ border: '1px solid #ccc', pb: 3, }}>
        //                                <MaterialReactTable
        //                                    columns={columns}
        //                                    data={flatData}
        //                                    displayColumnDefOptions={{
        //                                        'mrt-row-select': {
        //                                            maxSize: 12,
        //                                            header: '',
        //                                        },
        //                                        'mrt-row-actions': {
        //                                            header: '',
        //                                            maxSize: 30,
        //                                        },
        //                                    }}
        //                                    muiTablePaperProps={{
        //                                        sx: { border: '1px solid #000' },
        //                                    }}
        //                                    enableColumnActions={false}
        //                                    enableColumnFilters={false}
        //                                    enableSorting={false}
        //                                    enablePagination={false}
        //                                    enableRowNumbers={false}
        //                                    enableRowVirtualization
        //                                    enableHiding={false}
        //                                    enableColumnOrdering={false}
        //                                    muiTableBodyRowProps={({ row }) => ({
        //                                        onClick: () => {
        //                                            setRowSelection((prev) => ({
        //                                                [row.id]: true,
        //                                            }));
        //                                            HandleRowClick(row);
        //                                        },
        //                                        sx: {
        //                                            cursor: 'pointer',
        //                                        },
        //                                    })}
        //                                    positionActionsColumn="last"
        //                                    enableColumnResizing={false}
        //                                    enableDensityToggle={false}
        //                                    enableFullScreenToggle={false}
        //                                    muiTableBodyCellProps={({ column }) => ({
        //                                        sx: (theme) => ({
        //                                            color: theme.palette.text.secondary,
        //                                            fontSize: theme.typography.body1,
        //                                            lineHeight: 1,
        //                                            borderBottom: '1px solid #ccc',
        //                                            pl: 2, pr: 0, pt: 2, pb: 2
        //                                        }),
        //                                    })
        //                                    }

        //                                    muiTableHeadCellProps={{
        //                                        sx: (theme) => ({
        //                                            color: theme.palette.text.primary,
        //                                            fontSize: theme.typography.h6,
        //                                            borderBottom: '1px solid #ccc',
        //                                            fontWeight: 700, elevation: 0,
        //                                            pt: 0, pl: 2, pr: 0,
        //                                            pb: 1, textDecoration: 'underline'
        //                                        }),
        //                                    }}

        //                                    muiTableContainerProps={{
        //                                        ref: tableContainerRef,
        //                                        sx: { maxHeight: '700px' },
        //                                        onScroll: (
        //                                            event,
        //                                        ) => fetchMoreOnBottomReached(event.target),
        //                                    }}
        //                                    muiToolbarAlertBannerProps={
        //                                        isError
        //                                            ? {
        //                                                color: 'error',
        //                                                children: ErrorMessages.UnknownError,
        //                                            }
        //                                            : undefined
        //                                    }
        //                                renderBottomToolbarCustomActions={() => (
        //                                    <Box sx={{ mt: 2 }}>
        //                                        <Typography>
        //                                            {
        //                                                !isError && data
        //                                                    ? totalFetched + ' of ' + totalDBRowCount + ' role(s).'
        //                                                    : ''
        //                                            }
        //                                        </Typography>
        //                                    </Box>
        //                                    )}
        //                                    onRowSelectionChange={setRowSelection}
        //                                    state={{
        //                                        isLoading,
        //                                        showAlertBanner: isError,
        //                                        showProgressBars: isFetching,
        //                                        rowSelection,
        //                                    }}
        //                                    initialState={{
        //                                    }}
        //                                    muiTablePaperProps={{
        //                                        elevation: 0,
        //                                    }}
        //                                    rowVirtualizerProps={{ overscan: 1 }}
        //                                    localization={{
        //                                        noRecordsToDisplay: 'No roles to display',
        //                                        selectedCountOfRowCountRowsSelected: '',
        //                                    }}
        //                                    renderTopToolbarCustomActions={({ table }) => {
        //                                        return (
        //                                            <Box sx={{
        //                                                display: 'flex', alignItems: 'center', flexDirection: 'row'
        //                                            }}>
        //                                                <Typography variant="h6" color="text.primary" sx={{ pl: 2, pr: 4, fontWeight: 500, letterSpacing: '-1px' }}>Existing roles</Typography>
        //                                                <Button type="button" size="small" sx={{ }} onClick={(e) => userCountClick(e)}>
        //                                                    <Typography variant="body1" color="text.secondary" sx={{ textTransform: 'none' }} >{userCount}</Typography>
        //                                                </Button>
        //                                            </Box>
        //                                        );
        //                                    }}
        //                                    renderToolbarInternalActions={({ table }) => {
        //                                        return (
        //                                            <Box>
        //                                                <Tooltip arrow title="Edit role">
        //                                                    <span>
        //                                                        <IconButton sx={{}} disabled={enableEditBtn} onClick={() => handleEditClick(table)}>
        //                                                            <EditIcon width="24px" height="24px" color="#ccc" />
        //                                                        </IconButton>
        //                                                    </span>
        //                                                </Tooltip>
        //                                                <Tooltip arrow title="Delete role">
        //                                                    <span>
        //                                                        <IconButton disabled={enableDelBtn} onClick={() => handleDeleteClick(table)}>
        //                                                            <DeleteIcon />
        //                                                        </IconButton>
        //                                                    </span>
        //                                                </Tooltip>
        //                                            </Box>
        //                                        );
        //                                    }}
        //                                    tableInstanceRef={tableInstanceRef}
        //                                />
        //                                </Box>
        //                        {/*</Stack>*/}
        //                    </Grid>
        //                </Grid>
        //            </Grid>
        //            {
        //                openConfirmDlg ?
        //                    <AlertDialog
        //                        id="ringtone-menu"
        //                        keepMounted
        //                        open={openConfirmDlg}
        //                        onClose={handleConfirmDialogClose}
        //                        nvalue={selectedRoleTypId.current}
        //                        alertheader={confirmDlgHdr.current}
        //                        bmsgline1={confirmDlgBLine1.current}
        //                        bmsgline2={confirmDlgBLine2.current}
        //                        bmsgline3={confirmDlgBLine3.current}
        //                        bmsgline4={confirmDlgBLine4.current}
        //                    /> : ''
        //            }
        //        </Paper>
        //        <Footer />
        //    </Container>
        //</React.Fragment>
    );
};

//const queryClient = new QueryClient();

function RoleManagement() {
    //const RoleManagement = () => (

    const location = useLocation();
    const queryClient = new QueryClient();

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <ManageRole userId={userId} sessionId={sessionId} />
            </QueryClientProvider>
        </React.Fragment>
    );
}

function RoleListBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>
                <Typography variant="h5" color="text.primary" sx={{ lineHeight: 1, fontWeight: 400, letterSpacing: '-1px' }} >Role Management</Typography>
            </Box>

            {/*<Box sx={{ ml: 1, mr: 2, border: '0px solid #000', pt: 0 }}>*/}
            {/*    <Typography variant="h4" color="text.primary" sx={{ borderBottom: '1px solid #ccc', lineHeight: 2.5, fontWeight: 500, letterSpacing: '-1px' }} >Role Management</Typography>*/}
            {/*</Box>*/}

            {/*<Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6' }}>*/}
            {/*    <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">Role Management</Typography>*/}
            {/*</Box>*/}

            {/*<Box sx={{ borderLeft: '4px solid #42a5f5', mt: 0, pt: 2, pb: 2, pl: 1, ml: 2, backgroundColor: '#f8f9fa', borderTop: '1px solid #dee2e6', boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)' }}>*/}
            {/*    <Typography variant="h5" sx={{ fontWeight: 300, lineHeight: 1.1, pl: 1, textTransform: 'none' }} color="text.primary">Role Management</Typography>*/}
            {/*</Box>*/}

            {/*<Box sx={{ mt: 3, pt: 3, pb: 3, ml: 3, pl: 1, backgroundColor: '#f7f7fa'}}>*/}
            {/*    <Typography variant="h4" sx={{ fontWeight: 300, lineHeight: 1.1, pl: 1 }} color="text.primary">Role Management</Typography>*/}
            {/*</Box>*/}
            {/*<Box className="" style={{ backgroundColor: '#f8f8f8', padding: '12px 16px' }}>*/}
            {/*    <Typography className="ps-3" variant="h4" sx={{ fontWeight: 400, lineHeight: 1.1 }} color="text.primary">Form Templates</Typography>*/}
            {/*</Box>*/}
        </React.Fragment>
    );
}

export default RoleManagement;