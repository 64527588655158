import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import useHttp from '../../hooks/use-Http';
import Modal from 'react-bootstrap/Modal';
import LoaderControl from '../../Controls/LoaderControl';
import { useNavigate } from "react-router-dom";
import { ArrowRight } from 'react-bootstrap-icons';
import { ApiHelper } from '../../../customFunctions/apiHelper';
import { CallAPI, IsError, IsLoggedInUserProspectUser, GetLoggedInUserName, GetLoggedInUserId } from '../../../global/APIUtils';
import { ErrorMessages } from '../../../common/ErrorMessages';
import AlertControl from '../../Controls/AlertControl';
import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components';
import RiseLoader from 'react-spinners/RiseLoader'
import { EnrollmentStatus, ActivityLogEvent } from '../../../common/AOBEnum';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import OverlaySpinner from '../../Controls/OverlaySpinner';
import { Grid, TextField, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { APICall, WriteToActivityLog } from '../../../global/Api';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export function AddEditEnrollment(props) {

    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const [data, setData] = useState([]);
    const [dataOwner, setDataOwner] = useState([]);
    const [loading, setLoading] = useState(false);

    const [isReadOnly, setIsReadOnly] = useState(props.appreadonly || false);
    const [isAdminView, setIsAdminView] = useState(props.AdminView || false);

    const [TemplateId, setTemplateId] = useState(props.templateid || -1);
    const [name, setName] = useState(props.name || '');
    const [jointOwnerName, setJointOwnerName] = useState('');
    const [jointOwnerEmail, setJointOwnerEmail] = useState('');
    const [prevJointOwnerName, setPrevJointOwnerName] = useState('');
    const [prevJointOwnerEmail, setPrevJointOwnerEmail] = useState('');
    const [isContinueBtnEnabled, setIsContinueBtnEnabled] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateDesc, setTemplateDesc] = useState('');
    const [ownerId, setOwnerId] = useState(props.ownerid || -1);
    let history = useNavigate();
    const [CntCtId, setCntCtId] = useState(-1);
    const [CntCtNm, setCntCtNm] = useState('');
    const [roleId, setRoleId] = useState(-1);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [jntownrChecked, setJntownrChecked] = React.useState(false);

    const [disJointOwner, setDisJointOwner] = useState(true);

    const [userId, setUserId] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const enrmntId = useRef(props.enrmntid || -1);
    const enrollmentDetail = useRef('');
    const enrollmentStatusId = useRef(EnrollmentStatus.InComplete);

    const prevName = useRef(props.name || -1);
    const prevOwnerId = useRef(props.ownerid || -1);

    const handleClose = () => {
        props.onHide();
    };

    const [isProspectUser, setProspect] = useState(0);

    LoadingOverlay.propTypes = undefined;

    const handleChange = (event) => {
        setJntownrChecked(event.target.checked);

        if (event.target.checked) {
            setDisJointOwner(false);
        }
        else {
            setJointOwnerName('');
            setJointOwnerEmail('');
            setDisJointOwner(true);
        }
        setIsContinueBtnEnabled(name.trim().length > 0 && TemplateId > 0 && (!event.target.checked || (event.target.checked && jointOwnerName.trim().length > 0 && jointOwnerEmail.trim().length > 0)));
    };

    useEffect(() => {

        setProspect(IsLoggedInUserProspectUser(userId, sessionId) ? 1 : 0);
        setCntCtNm(GetLoggedInUserName(userId, sessionId));
        setCntCtId(userId);
        loadTemplateOwner();

    }, []);


    const loadTemplateOwner = async () => {

        try {


            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_PUBLISHED_TEMPLATES,
                process.env.REACT_APP_BASE_URL,
            );

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                let templateData = response["data"];
                if (templateData == null || templateData == undefined) {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
                else {
                    setData(templateData);
                }
            }

            apiUrl = new URL(
                process.env.REACT_APP_GET_PROSPECTS,
                process.env.REACT_APP_BASE_URL,
            );

            //response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                let userData = response["data"];
                if (userData == null || userData == undefined) {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
                else {
                    setDataOwner(userData);
                }
            }

            if (enrmntId.current != -1) {

                apiUrl = new URL(
                    process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
                    process.env.REACT_APP_BASE_URL,
                );
                apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntId.current)}`);

                //response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
                response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    enrollmentDetail.current = response["data"][0].enrmntData;
                    setOwnerId(response["data"][0].ownerId);
                    prevOwnerId.current = parseInt(response["data"][0].ownerId);
                    setName(response["data"][0].name);
                    setJointOwnerName(response["data"][0].JointOwnerName);
                    setJointOwnerEmail(response["data"][0].JointOwnerEmail);
                    setPrevJointOwnerName(response["data"][0].JointOwnerName);
                    setPrevJointOwnerEmail(response["data"][0].JointOwnerEmail);
                    setJntownrChecked(response["data"][0].IsJointAccount);
                    if (response["data"][0].IsJointAccount) {
                        setDisJointOwner(false)
                    }
                    enrollmentStatusId.current = response["data"][0].enrmntStatusId;
                    setIsContinueBtnEnabled(true);
                }
            }
        }
        catch (err) {
            console.error(err.message);
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const nameChangeHandler = (nm) => {
        setName(nm)
        setIsContinueBtnEnabled(nm.trim().length > 0 && TemplateId > 0 && (!jntownrChecked || (jntownrChecked && jointOwnerName.trim().length > 0 && jointOwnerEmail.trim().length > 0)));
    }

    const jointOwnerNameChangeHandler = (nm) => {
        setJointOwnerName(nm)
        setIsContinueBtnEnabled(name.trim().length > 0 && TemplateId > 0 && (!jntownrChecked || (jntownrChecked && nm.trim().length > 0 && jointOwnerEmail.trim().length > 0)));
    }

    const jointOwnerEmailChangeHandler = (nm) => {
        setJointOwnerEmail(nm)
        setIsContinueBtnEnabled(name.trim().length > 0 && TemplateId > 0 && (!jntownrChecked || (jntownrChecked && jointOwnerName.trim().length > 0 && nm.trim().length > 0)));
    }

    const changeCboTemplate = (tmpltId) => {

        if (enrmntId.current > 0 && tmpltId !== TemplateId) {
            alert('Application Type changing not allow.')
            return;
        }

        setTemplateId(tmpltId)
        setTemplateDesc('Select Application Type')

        var desc = data.filter(item => item.TemplateId == tmpltId)
        if (desc.length > 0) {
            setTemplateName(desc[0].TemplateName)
            setTemplateDesc(desc[0].templateDesc)
        }

        setIsReadOnly(tmpltId <= 0);
        setIsContinueBtnEnabled(name.trim().length > 0 && tmpltId > 0 && (!jntownrChecked || (jntownrChecked && jointOwnerName.trim().length > 0 && jointOwnerEmail.trim().length > 0)));
    }
    const changeCboOwner = (CntCtId) => {
        setOwnerId(CntCtId)
    }


    //const handleClose = () => setShow(false);

    const addEnrollment = async () => {

        let tmpltId = TemplateId || props.templateid;
        let selOwnerId = ((isProspectUser === 1) ? CntCtId : ownerId);

        if (selOwnerId == -1) {
            setErrorMsg('Please select at least one of the owner when creating an application.');
            return;
        }

        if (jntownrChecked && jointOwnerName.length == 0) {
            setErrorMsg('Please enter joint owner\'s name.');
            return;
        }

        if (jntownrChecked && jointOwnerEmail.length == 0) {
            setErrorMsg('Please enter joint owner\'s email.');
            return;
        }

        if (jntownrChecked && !jointOwnerEmail.match(validEmailRegex)) {
            setErrorMsg('Please enter valid joint owner\'s email address.');
            return;
        }

        let inputJson;
        let apiUrl;

        try {
            setIsLoading(true);
            let apiUrl = null;

            apiUrl = new URL(
                process.env.REACT_APP_ADD_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            inputJson = {
                ownerId: parseInt(selOwnerId),
                templateId: parseInt(tmpltId),
                name: name,
                IsJointAccount: jntownrChecked,
                JointOwnerName: jntownrChecked ? jointOwnerName : '',
                JointOwnerEmail: jntownrChecked ? jointOwnerEmail : ''
            };

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "New application created. Application name: " + name, userId, sessionId);

                enrmntId.current = response["data"][0].EnrolmentID;
                handleClose();
                history('/Enrollment', { state: { templateid: parseInt(tmpltId), name: name, enrmntid: enrmntId.current, ownerid: selOwnerId, templatenm: (templateName || props.templatenm), userId: userId, sessionId: sessionId, appreadonly: isReadOnly, AdminView: isAdminView, EnrollmentStatus: EnrollmentStatus.InComplete }, replace: true })
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {

        }
    }

    const modifyEnrollment = async () => {

        let tmpltId = TemplateId || props.templateid;
        let selOwnerId = ((isProspectUser === 1) ? CntCtId : ownerId);


        if (selOwnerId == -1) {
            setErrorMsg('Please select at least one of the owner when creating an application.');
            return;
        }

        if (jntownrChecked && jointOwnerName.length == 0) {
            setErrorMsg('Please enter joint owner\'s name.');
            return;
        }

        if (jntownrChecked && jointOwnerEmail.length == 0) {
            setErrorMsg('Please enter joint owner\'s email.');
            return;
        }

        if (jntownrChecked && !jointOwnerEmail.match(validEmailRegex)) {
            setErrorMsg('Please enter valid joint owner\'s email address.');
            return;
        }

        if (prevName.current === name && prevOwnerId.current === ownerId
            && jointOwnerName == prevJointOwnerName && jointOwnerEmail == prevJointOwnerEmail) {
            handleClose();
            history('/Enrollment', { state: { templateid: parseInt(tmpltId), name: name, enrmntid: enrmntId.current, ownerid: selOwnerId, templatenm: (templateName || props.templatenm), userId: userId, sessionId: sessionId, appreadonly: isReadOnly, AdminView: isAdminView, EnrollmentStatus: enrollmentStatusId.current }, replace: true });
            return;
        }

        let inputJson;
        let apiUrl;

        try {

            setIsLoading(true);
            let apiUrl = null;

            apiUrl = new URL(
                process.env.REACT_APP_MODIFY_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            inputJson = {
                enrmntId: parseInt(enrmntId.current),
                ownerId: parseInt(selOwnerId),
                templateId: parseInt(tmpltId),
                enrmntData: enrollmentDetail.current,
                mntStatusId: parseInt(null),
                enrmntStatusId: enrollmentStatusId.current,
                name: name,
                IsJointAccount: jntownrChecked,
                JointOwnerName: jntownrChecked ? jointOwnerName : '',
                JointOwnerEmail: jntownrChecked ? jointOwnerEmail : ''
            };

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                enrmntId.current = response["data"][0].EnrolmentID;

                await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Application edited. Application name: " + name, userId, sessionId);

                handleClose();
                history('/Enrollment', { state: { templateid: parseInt(tmpltId), name: name, enrmntid: enrmntId.current, ownerid: selOwnerId, templatenm: (templateName || props.templatenm), userId: userId, sessionId: sessionId, appreadonly: isReadOnly, AdminView: isAdminView, EnrollmentStatus: enrollmentStatusId.current }, replace: true });
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {

        }
    }

    const handleContinue = async () => {

        if (enrmntId.current == -1) {
            addEnrollment();
        }
        else {
            modifyEnrollment();
        }
    };

        return (
            <>
                
                    <Dialog
                    open={props.show}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    keepMounted
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "800px",  
                            },
                        },
                    }}
                >
                    <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                        <DialogTitle>
                            <Box sx={{pl: 1.5, pt: 1}}>
                                <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                                    {props.title}
                                </Typography>
                            </Box>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 12,
                                    color: (theme) => theme.palette.Icon.main,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box sx={{pl: 4, pr: 4, pb: 1}}>
                                <Grid container>
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                    <Grid item md={9} sx={{ pt: 1 }}>
                                        <label htmlFor="txtName" className="form-label">How do you want to save this application *</label>
                                        <input type="text" id="txtName" name="txtName" placeholder="" className="form-control" value={name} onChange={(event) => nameChangeHandler(event.target.value)} disabled={(enrollmentStatusId.current != EnrollmentStatus.InComplete && enrollmentStatusId.current != EnrollmentStatus.Complete) ? true : false} />
                                    </Grid>
                                    <Grid item item md={9} sx={{pt: 1.5}}>

                                        <label htmlFor="cboTemplate" className="form-label">Choose the account you want to open *</label>
                                        <select id="cboTemplate" className="form-select" name="cboTemplate" value={TemplateId} onChange={(event) => changeCboTemplate(event.target.value)} placeholder="" disabled={(enrmntId.current > 0)}>
                                            <option key={-1} value={-1}>Select</option>
                                            {
                                                data && data.map(item => (
                                                    <option key={item.TemplateId} value={item.TemplateId}>{item.TemplateName}</option>
                                                ))
                                            }
                                        </select>

                                    </Grid>
                                    {
                                        (isProspectUser === 1) ?

                                        <Grid item md={9} sx={{ mb: 1, pt: 1.5}}>
                                            <label htmlFor="txtOwner" className="form-label">Owner *</label>
                                            <input type="text" id="txtOwner" name="txtOwner" placeholder="" className="form-control" value={CntCtNm} disabled={true} />
                                        </Grid>
                                        :
                                            <Grid item md={9} sx={{ mb: 1, pt: 1.5}}>
                                                <label htmlFor="cboOwner" className="form-label">Choose owner *</label>
                                                <select id="cboOwner" className="form-select" name="cboOwner" value={ownerId} onChange={(event) => changeCboOwner(event.target.value)} placeholder="" disabled={(isProspectUser === 1 || (enrollmentStatusId.current != EnrollmentStatus.InComplete && enrollmentStatusId.current != EnrollmentStatus.Complete)) || isReadOnly ? true : false}>
                                                <option key={-1} value={-1}>Select</option>
                                                {
                                                    dataOwner && dataOwner.map(item => (
                                                        <option key={item.UserId} value={item.UserId}>{item.FullName}</option>
                                                    ))
                                                }
                                            </select>
                                        </Grid>
                                    }
                                    <Grid item item md={9}>
                                        <FormControlLabel
                                            label={<Typography sx={{fontWeight: 400}} variant="body4" color="textSecondary">Joint account</Typography>}
                                            control={<Checkbox sx={{
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: 'Check.main',
                                                },
                                            }} checked={jntownrChecked} onChange={handleChange} />}
                                        />
                                    </Grid>
                                    <Grid item container direction="row" spacing={2} sx={{ pb: 2, pt: 0.5 }}>
                                        <Grid item md={6} sx={{ pt: 1 }}>
                                            <label htmlFor="txtJointOwnerName" className="form-label">Joint owner's name *</label>
                                            <input type="text" disabled={disJointOwner} id="txtJointOwnerName" name="txtJointOwnerName" placeholder="" className="form-control" value={jointOwnerName} onChange={(event) => jointOwnerNameChangeHandler(event.target.value)} />
                                        </Grid>
                                        <Grid item md={6} sx={{ pt: 1 }}>
                                            <label htmlFor="txtJointOwnerEmail" className="form-label">Joint owner's email address *</label>
                                            <input type="email" disabled={disJointOwner} id="txtJointOwnerEmail" name="txtJointOwnerEmail" placeholder="" className="form-control" value={jointOwnerEmail} onChange={(event) => jointOwnerEmailChangeHandler(event.target.value)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ mr: 3.5, pb: 1.5 }}>

                            <Button autoFocus onClick={handleClose} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2rem', mr: 1.5, textTransform: 'none' }}>
                                Cancel
                            </Button>
                            <Button onClick={handleContinue} disabled={!isContinueBtnEnabled} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2rem', mr: 1.5, textTransform: 'none' }}>
                                Proceed
                            </Button>
                            </DialogActions>
                        {/*</form>*/}
                    </LoadingOverlay>
                </Dialog>                
            </>
        );
};

