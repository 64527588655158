import React, { useState, useRef, useEffect, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Link, Stack, Toolbar, Box, Alert, FormControl, TextField, Card, Divider } from '@mui/material';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import Button from '@mui/material/Button';
import Footer from './Footer';
import Grid from '@mui/material/Grid';
//import pwdimg from '../assets/images/mbr-13-816x544.jpg';
//import Image from 'material-ui-image'
import AlertControl from './Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
import { PreLoginMenu } from './PreLoginMenu';
import { CallServerAPI, IsError } from '../global/APIUtils';
import { ServerAPICall, AddToSession, WriteToActivityLogWL } from '../global/Api';
//import clientlogo from '../assets/images/demo_bank_logo.jpg';
import ClientLogoHeader from './ClientLogoHead'
import { ActivityLogEvent } from '../common/AOBEnum';

const VerifyResetCode = (props) => {

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [blurEmail, setBlurEmail] = useState('');
    const [counter, setCounter] = useState(120);
    const [runTimer, setRunTimer] = useState(true);
    const [resendTextDisp, setResendTextDisp] = useState('block');
    const [resendButtonDisp, setResendButtonDisp] = useState('none');
    const [disSubmitBtn, setDisSubmitBtn] = useState(false);

    const emailAddress = useRef(location.state.emailAddress);

    let navigate = useNavigate();

    //const id = setInterval(() => {
    //    setResetCounter();
    //}, 1000);

    const resetCodeForm = useFormik({
        initialValues: {
            ResetCode: ''
        },
        validationSchema: yup.object({
            ResetCode: yup.string()
                .max(30, "Verification code - must be a string with a maximum length of 30")
                .required('Verification code - required.'),
        }),
        onSubmit: values => {
            //setEmail(values.ResetCode)
            handleSubmit(values);
        }
    });

    const handleSubmit = async (resetValues) => {

        let loginData = {
            EmailAddress: emailAddress.current,
            ResetPasswordCode: resetValues.ResetCode
        };


        setErrorMsg('');

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_VERIFY_RESET_CODE,
                process.env.REACT_APP_BASE_URL,
            );

            //let endpoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERIFY_RESET_CODE;

            //let response = await CallServerAPI({ url: apiUrl.href, method: 'POST', headers: headers, form: loginData });
            let response = await ServerAPICall({ url: apiUrl.href, method: 'POST', payload: loginData, contentType: 'application/json', responseType: '' });

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                if (response["data"][0]["Access_Token"] !== null && typeof (response["data"][0]["Access_Token"]) !== "undefined") {

                    let userId = parseInt(response["data"][0]["UserID"]);
                    let sessionId = response["data"][0]["SessionID"];

                    await WriteToActivityLogWL(ActivityLogEvent.ForgotPassword, "Password reset code verification successful. User: '" + emailAddress.current + "'");

                    AddToSession(response, emailAddress.current);
                    navigate('/ChangePasswordOption', { state: { userId: userId, sessionId: sessionId }, replace: true });
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    function setResetCounter() {
        if (runTimer) {
            if (parseInt(counter) == 0) {
                setRunTimer(false);
                setResendTextDisp('none');
                setResendButtonDisp('block');
                setDisSubmitBtn(true);
                return;
            }

            setCounter(counter - 1);
        }
    }

    useEffect(() => {

        if (emailAddress.current) {
            let arr = emailAddress.current.split("@");
            let len = arr[0].length;
            setBlurEmail(arr[0][0] + '*****' + (len > 1 ? arr[0][len - 2] : '') + arr[0][len - 1] + '@' + arr[1]);
        }

    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            setResetCounter();
        }, 1000);

        return () => {
            clearInterval(id);
        };
    });

    const login = (event) => {
        event.preventDefault();
        navigate('/AppLogin', { state: {}, replace: true });
    };

    const ResendCode = async (event) => {

        event.preventDefault();

        let resetPwdModel = {
            EmailAddress: emailAddress.current
        };

        setErrorMsg('');

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_RESEND_PASSWORD_RESET_CODE,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await ServerAPICall({ url: apiUrl.href, method: 'POST', payload: resetPwdModel, contentType: 'application/json', responseType: '' });

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }

                setCounter(120);
                setRunTimer(true);
                setResendTextDisp('block');
                setResendButtonDisp('none');
                setDisSubmitBtn(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth="lg" disableGutters={true} >
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper className="back-grid">
                    <ClientLogoHeader />
                    {/*<Toolbar sx={{ borderBottom: 1, borderColor: '#f5f5f5', justifyContent: 'space-between', backgroundColor: '#fff', pt: 1, pb: 1, boxShadow: '0 1px 5px 0 rgba(32, 33, 36, 0.28)' }}>*/}
                    {/*    <Box sx={{ ml: 2, pt: 2, pb: 2 }}>*/}
                    {/*        <img src={clientlogo} height="42" loading="lazy" />*/}
                    {/*    </Box>*/}
                    {/*</Toolbar>*/}

                    {/*<Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between', backgroundColor: '#fff', pt: 0, pb: 0 }}>*/}

                    {/*    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 4, mb: 1, pb: 1 }}>*/}
                    {/*        <img src={clientlogo} height="44" loading="lazy" />*/}
                    {/*    </Box>*/}
                    {/*</Toolbar>*/}

                    <Grid container spacing={5} sx={{ pt: 5.5 }}>
                        <Grid item md={12} sx={{ pr: 2, }} justifyContent="center">
                            <Card elevation={3} sx={{ mt: 3, width: '410px', mx: 'auto', border: 0, borderColor: '#16191f', borderRadius: '6px', pt: 4, pb: 14, pl: 4, pr: 4 }} >
                                <Typography variant="h4" color="text.secondary" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, pb: 1.5, borderBottom: '0px solid #898F94', letterSpacing: '0px' }}>Enter verification code</Typography>
                                {/*<Typography variant="h4" color="text.secondary" sx={{ fontSize: '22px', fontWeight: 500, mb: 2.5, pb: 2, borderBottom: '1px solid #9FA4A8' }}>Enter verification code</Typography>*/}
                                <Box sx={{ ml: 0, mr: 0, }}>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#BBBFBF' }} />
                                </Box>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ textAlign: 'center', pt: 2.5, fontWeight: 500 }}>
                                        sent to your email {blurEmail}
                                    </Typography>
                                </Box>
                                <Box sx={{ pt: 6.5, display: 'flex', justifyContent: 'center' }}>
                                    <form onSubmit={resetCodeForm.handleSubmit} id="resetCodeForm" >
                                        <Box>
                                            <label htmlFor="ResetCode" className="form-label">Verification Code*</label>
                                            <input type="text" id="ResetCode" name="ResetCode" style={{ width: '310px' }} autoComplete="off" placeholder="Enter code here" className="form-control" value={resetCodeForm.values.ResetCode} onChange={resetCodeForm.handleChange} />
                                            {
                                                resetCodeForm.touched.ResetCode && resetCodeForm.errors.ResetCode ? (<p className="text-danger">{resetCodeForm.errors.ResetCode}</p>) : null
                                            }
                                        </Box>
                                        <Typography variant="body1" color="text.primary" sx={{ pt: 2, display: resendTextDisp }}>
                                            Resend code in {counter}
                                        </Typography>
                                        <Box sx={{ pt: 1, display: resendButtonDisp }}>
                                            <Link underline="hover" sx={{ textTransform: 'none', color: 'Link.main' }} onClick={(e) => ResendCode(e)}>
                                                Resend code
                                            </Link>
                                        </Box>
                                        <Box sx={{ mt: 5.5, display: 'flex', justifyContent: 'center' }}>
                                            <Button type="submit" variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.3rem 2.8rem', textTransform: 'none' }} >
                                                Verify
                                            </Button>

                                            {/*    <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#616161' }}>*/}
                                            {/*        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Verify</Typography>*/}
                                            {/*    </Button>*/}
                                        </Box>
                                    </form>
                                </Box>
                                <Box sx={{ pt: 2.5, display: 'flex', justifyContent: 'center' }}>
                                    <Link onClick={login} underline="hover" sx={{ textTransform: 'none', color: 'Link.main' }}>Back to Login</Link>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item md={2} sx={{ pb: 4 }}>
                        </Grid>
                    </Grid>
                    <Footer />
                </Paper>
            </Container>
        </React.Fragment>
    );
}
export default VerifyResetCode;