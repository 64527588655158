import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, Box, FormGroup, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { CallAPIUsingAxios, IsAxiosError } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { GetApplicantImage } from '../global/EnrollmentHelper';
import { APICall, WriteToActivityLog } from '../global/Api';
import { IsInRole } from '../global/APIUtils';
import { AOBSystemProcess, ActivityLogEvent } from '../common/AOBEnum';
import { Stack } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

LoadingOverlay.propTypes = undefined;

const DocUpldDlg = (props) => {

    const { onClose, open, onChange, enrmntid, enrolNm } = props;
    const [invalidDoc, setInvalidDoc] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [uploadedDoc, setUploadedDoc] = useState(null);
    const [isOkBtnEnabled, setIsOkBtnEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [isSignReq, setIsSignReq] = useState(true);
    const [checked, setChecked] = React.useState(true);
    const [disChecked, setDisChecked] = React.useState(false);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    const handleCheckChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleOk = async (event) => {
        event.preventDefault();

        if (!invalidDoc) {

            try {

                let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
                let canUpload = IsInRole(AOBSystemProcess.DocuSignDocUpload, userId, sessionId) || isAdmin;
                if (!canUpload) {
                    setErrorMsg('You don’t have permission to upload file for DocuSign.');
                    return;
                }

                setErrorMsg('');
                setIsLoading(true);

                const apiUrl = new URL(
                    process.env.REACT_APP_SAVE_ENROLLMENT_DOC,
                    process.env.REACT_APP_BASE_URL,
                );

                const fileFormData = new FormData();
                fileFormData.append("enrmntId", parseInt(props.enrmntid));
                fileFormData.append("DocumentFile", uploadedDoc);
                fileFormData.append("FileName", fileName);
                fileFormData.append("IsSignReq", checked);
                fileFormData.append("enrmntNm", enrolNm);

                //let response = await CallAPIUsingAxios({ url: apiUrl.href, method: 'POST', formData: fileFormData }, userId, sessionId);

                let response = await APICall({ url: apiUrl.href, method: 'POST', payload: fileFormData, contentType: 'multipart/form-data', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {

                    setIsLoading(false);

                    let error = IsAxiosError(response);

                    if (error != 0) {
                        setErrorMsg(response.Errors[0].Message);
                        return;
                    }

                    await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Document uploaded to the application. Application name: '" + enrolNm + "'. File name: '" + fileName + "'. DocuSign required: '" + (checked ? "Yes" : "No") + "'.", userId, sessionId);

                    onChange();
                }
            }
            catch (err) {

                setErrorMsg(ErrorMessages.UnknownError);
                setIsLoading(false);
            }
            finally {
            }
        }
        else if (!invalidDoc) {
            setErrorMsg('Please select a valid file.');
        }
    };

    const onChangeFile = (event) => {
        const uploadedFile = event.target.files[0];

        const reader = new FileReader();

        setErrorMsg('');
        if (!uploadedFile) {
            setErrorMsg("Please select a file.");
            setInvalidDoc(true);
            return false;
        }

        if (!uploadedFile.name.match(/\.(pdf)$/)) {
            setErrorMsg("Please select a valid PDF file.");
            setInvalidDoc(true);
            return false;
        }

        if (uploadedFile.type !== 'application/pdf') {
            setErrorMsg("Please select a valid PDF file.");
            setInvalidDoc(true);
            return false;
        }

        if (Math.floor(uploadedFile.size / 1048576) > 10) {   /* 2MB */
            setErrorMsg("File size exceeds the limit allowed (10MB) and cannot be uploaded.");
            setInvalidDoc(true);
            event.preventDefault();
            return false;
        }

        //reader.onload = (e) => {
        //    const img = new Image();
        //    img.onload = () => {
        //        setPicUpload(imageFile);
        //        setInvalidImage(false);
        //    };
        //    img.onerror = () => {
        //        setErrorMsg("Invalid image content.");
        //        setInvalidImage(true);
        //        return false;
        //    };

        //    setImageUrl(e.target.result);
        //    img.src = e.target.result;
        //};
        reader.readAsDataURL(uploadedFile);
        setUploadedDoc(uploadedFile);
        setFileName(uploadedFile.name);
        setIsOkBtnEnabled(true);
    }

    useEffect(() => {
        loadForm();
    }, []);

    const loadForm = async () => {

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
        let canUploadDDoc = IsInRole(AOBSystemProcess.DocuSignDocUpload, userId, sessionId) || isAdmin;
        if (!canUploadDDoc) {
            setChecked(false);
            setDisChecked(true);
        }
    }

    return (
        <Dialog onClose={handleClose} open={open} keepMounted>
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle>
                    <Box sx={{ pl: 1.5, pt: 1 }}>
                        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                            Upload document
                        </Typography>
                    </Box>                   
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 12,
                            color: (theme) => theme.palette.Icon.main,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Grid container>
                        <Grid item md={11} sx={{}}>
                            <Box sx={{ pl: 2, pr: 2 }}>
                                <Stack direction="column">
                                    <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                        Owner: {props.ownerName}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                        Application name: {props.enrolNm}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                        Applied for: {props.templateNm}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item md={11} sx={{ pt: 2 }}>
                            <Box sx={{ pl: 2, pr:2}}>
                                <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                    Please choose a file that’s less than 10MB size, and format should be PDF
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={11} sx={{ pt: 2 }}>
                            <Box sx={{ pl: 2, pr: 2 }}>
                                <Typography sx={{ mb: 1, fontWeight: 500 }} variant="body2" color="text.primary">Document</Typography>
                            </Box>
                        </Grid>
                        {/*<Grid item md={11} sx={{ pt: 1 }}>*/}
                        {/*    {imageUrl ?*/}
                        {/*        <img src={imageUrl} alt="Prifile Picture" className="prof-pic-prvw" />*/}
                        {/*        :*/}
                        {/*        <img src={profimg} alt="Prifile Picture" className="prof-pic-prvw" />*/}
                        {/*    }*/}
                        {/*</Grid>*/}
                        <Grid item md={11} sx={{ pt: 1, pb: 1 }}>
                            <Box sx={{ pl: 2, pr: 2 }}>
                                <FormControl variant="outlined">
                                    <input type="file" className="prof-pic-upld-ctrl" id="prof-pic-upld" onChange={onChangeFile} accept="application/pdf" />
                                </FormControl>
                            </Box>
                            <Box sx={{ pl: 2, pr: 2, pt: 1}}>
                                <FormGroup>
                                    <FormControlLabel disabled={disChecked} control={<Checkbox sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'Check.main',
                                        },
                                    }} checked={checked} onChange={handleCheckChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="textSecondary">Requires DocuSign</Typography>} />
                                </FormGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2.5, pb: 1.5 }}>
                    <Button autoFocus onClick={handleClose} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2rem', mr: 1.5, textTransform: 'none' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleOk} disabled={!isOkBtnEnabled} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2rem', textTransform: 'none' }}>
                            Upload
                    </Button>
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default DocUpldDlg;
