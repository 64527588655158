import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, Container, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, Box, Paper, Card, CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import noimg from '../assets/images/NoImage.png';
import { UploadUserFiles } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';

const BankResourceDlg = (props) => {
    const { onClose, open, onChange, cntctid } = props;
    const [invalidImage, setInvalidImage] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [picUpload, setPicUpload] = useState(null);
    const [isOkBtnEnabled, setIsOkBtnEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [imageHeight, setImageHeight] = useState(0);
    const [noImageHeight, setNoImageHeight] = useState(0);
    const [noImageWidth, setNoImageWidth] = useState(0);
    const [imageType, setImageType] = useState(props.ImageType || 1);
    const [dialogMaxWidth, setDialogMaxWidth] = useState('sm');

    const [imgAspectRatio, setImgAspectRatio] = useState(0.0);

    const [imageFileName, setImageFileName] = useState(null);


    const [imageRealWidth, setImageRealWidth] = useState(0);
    const [imageRealHeight, setImageRealHeight] = useState(0);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    const GetResourceTypeStr = (resType) => {

        switch (resType) {
            case 1:
                return "Bank logo";
            case 2:
                return "Bank logo2";
            case 3:
                return "Login page image";
            case 4:
                return "Prospect home page image";
            default:
                return "";
        }
    }

    const handleOk = async (event) => {
        event.preventDefault();

        if (!invalidImage) {

            try {

                setIsLoading(true);

                const formData = new FormData();
                formData.append("ResourceType", parseInt(imageType));
                formData.append("ResourceFileName", imageFileName);
                formData.append("ResourceFile", picUpload);
                formData.append("ImageAspectRatio", imgAspectRatio);

                const apiUrl = new URL(
                    process.env.REACT_APP_SAVE_BANK_RESOURCE,
                    process.env.REACT_APP_BASE_URL,
                );

                let response = await APICall({ url: apiUrl.href, method: 'POST', payload: formData, contentType: 'multipart/form-data', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {

                    if (response.meta.Updated == "1") {
                        await WriteToActivityLog(ActivityLogEvent.SystemOptions, GetResourceTypeStr(imageType) + " updated.", userId, sessionId);
                        onChange();
                    }
                    else
                        setErrorMsg(ErrorMessages.UnknownError);
                }
            }
            catch (err) {
                setErrorMsg(ErrorMessages.UnknownError);
            }
            finally {
                setIsLoading(false);
            }
        }
        else if (!imageUrl) {
            setErrorMsg('Please select a valid image file.');
        }
    };

    const onChangeFile = (event) => {
        const imageFile = event.target.files[0];

        const reader = new FileReader();

        setErrorMsg('');
        if (!imageFile) {
            setErrorMsg("Please select an image.");
            setInvalidImage(true);
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            setErrorMsg("Please select a valid image file.");
            setInvalidImage(true);
            return false;
        }

        if (imageFile.type !== 'image/jpeg' && imageFile.type !== 'image/png') {
            setErrorMsg("Please select a valid image file.");
            setInvalidImage(true);
            return false;
        }

        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                setPicUpload(imageFile);
                setInvalidImage(false);
                let aspectRatio = img.naturalWidth / img.naturalHeight;
                aspectRatio = aspectRatio.toFixed(8);
                setImageRealWidth(imageHeight * aspectRatio);
                setImgAspectRatio(aspectRatio);
            };
            img.onerror = () => {
                setErrorMsg("Invalid image content.");
                setInvalidImage(true);
                return false;
            };

            setImageUrl(e.target.result);
            img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);
        setIsOkBtnEnabled(true);
        setImageFileName(imageFile.name);
    }

    const loadBankResource = async () => {

        try {

            let imgHt = 40

            if (parseInt(imageType) == 1) {
                setDialogMaxWidth('sm');
                imgHt = 40;
                setImageHeight(40);
                setNoImageHeight(80);
                setNoImageWidth(100);
            }
            else if (parseInt(imageType) == 2) {
                setDialogMaxWidth('sm');
                imgHt = 40;
                setImageHeight(40);
                setNoImageHeight(80);
                setNoImageWidth(100);
            }
            else if (parseInt(imageType) == 3) {
                setDialogMaxWidth('md');
                imgHt = 450;
                setImageHeight(450);
                setNoImageHeight(150);
                setNoImageWidth(200);
            }
            else if (parseInt(imageType) == 4) {
                setDialogMaxWidth('md');
                imgHt = 410;
                setImageHeight(410);
                setNoImageHeight(150);
                setNoImageWidth(200);
            }

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_BANK_RESOURCE,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('resourceType', `${parseInt(imageType)}`);

            let imageURL = await APICall({ url: apiUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

            if (imageURL) {
                if (imageURL.type == 'image/jpeg') {
                    let img = URL.createObjectURL(imageURL);
                    setImageUrl(img);
                }
            }

            apiUrl = new URL(
                process.env.REACT_APP_GET_IMAGE_ASPECT_RATIO,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('resourceType', `${parseInt(imageType)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== undefined && response !== null) {
                setImgAspectRatio(response.data[0]);
                setImageRealWidth(imgHt * response.data[0]);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        loadBankResource();
    }, []);

    return (
        <Dialog onClose={handleClose} open={open} keepMounted maxWidth={dialogMaxWidth}>
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle>

                    <Box sx={{ pl: 1.5, pt: 1 }}>
                        <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                            {'Update ' +  GetResourceTypeStr(imageType)}
                        </Typography>
                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.Icon.main,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Grid container sx={{pl:2, pr: 2}}>
                        <Grid item md={11} sx={{}}>
                            <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body4" color="text.primary">
                                Please choose a photo that’s less than 2MB size, and format should be PNG,JPG,JPEG
                            </Typography>
                        </Grid>
                        <Grid item md={11} sx={{ pt: 2 }}>
                            <Typography sx={{ mb: 1, fontWeight: 500 }} variant="body1" color="text.primary">Preview</Typography>
                        </Grid>
                        <Grid item md={11} sx={{ pt: 1 }}>
                            {imageUrl ?
                                <img src={imageUrl} style={{ display: 'inline-block', maxWidth: imageRealWidth, height: imageHeight }} />
                                :
                                <img src={noimg} style={{ display: 'inline-block', maxWidth: noImageWidth, height: noImageHeight }}  />
                            }
                        </Grid>
                        <Grid item md={11} sx={{ pt: 3, pb: 2 }}>
                            <FormControl variant="outlined">
                                <input type="file" className="prof-pic-upld-ctrl" id="prof-pic-upld" onChange={onChangeFile} accept=".jpg,.jpeg,.png" />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2, pb: 2 }}>
                    <Button autoFocus onClick={handleClose} variant="contained" sx={{ mb: 1, mt: 1, borderRadius: '20px', padding: '0.25rem 2.2rem', mr: 1.5, textTransform: 'none' }}>
                            Cancel
                    </Button>
                    <Button onClick={handleOk} disabled={!isOkBtnEnabled} variant="contained" sx={{ mb: 1, mt: 1, borderRadius: '20px', padding: '0.25rem 2.2rem', mr: 1.5, textTransform: 'none' }}>
                            Upload
                    </Button>
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default BankResourceDlg;
