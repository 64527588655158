import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export function AlertDialog(props) {
    const { onClose, value: valueProp, open, ...other } = props;

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(props.nvalue, props.svalue);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
            maxWidth="sm"
            open={open}
            {...other}
        >
            <DialogTitle>
                <Box sx={{ pl: 1.5, pt: 1, pr: 6, pb: 1 }}>
                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                        {props.alertheader}
                    </Typography>
                </Box>
                {/*<Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>*/}
                {/*    {props.alertheader}*/}
                {/*</Typography>*/}
                <IconButton
                    aria-label="close"
                    onClick={handleCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.Icon.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ pl: 2, pr: 2, pb: 1 }}>
                    <Stack direction="column" spacing={0.5}>
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline1}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline2}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline3}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline4}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline5}</Typography>
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions sx={{ mr: 1.5, pb: 2.5 }}>
                <Box sx={{ pt: 1 }}>
                    <Stack direction="row" spacing={0.5}>
                        <Box sx={{}}>
                            <Button autoFocus variant="contained" onClick={handleCancel} size="small" sx={{ borderRadius: '20px', padding: '0.25rem 2.5rem', mr: 1.5, textTransform: 'none'}}>
                                No
                            </Button>
                        </Box>
                        <Box sx={{ pr: 1 }}>
                            <Button variant="contained" onClick={handleOk} size="small" sx={{ borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }}>
                                Yes
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

AlertDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    nvalue: PropTypes.number,
    svalue: PropTypes.string,
    alertheader: PropTypes.string.isRequired,
    bmsgline1: PropTypes.string.isRequired,
    bmsgline2: PropTypes.string,
    bmsgline3: PropTypes.string,
    bmsgline4: PropTypes.string,
    bmsgline5: PropTypes.string,
};