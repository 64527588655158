import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Link, Stack, Box, Divider, Toolbar, IconButton, Tooltip } from '@mui/material';
import { ServerAPICall } from '../global/Api';
import { GetRoleNameOfUser } from '../global/APIUtils';
import { useThemeContext } from "../Theme/ThemeContextProvider";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const Footer = (props) => {

    const { theme, toggleColorMode } = useThemeContext();

    const [copyrightText, setCopyrightText] = useState('');
    const [roleName, setRoleName] = useState('');
    const [colorMode, setColorMode] = useState(theme.palette.mode);

    const loadConfig = async () => {
        try {

            let themeMode = localStorage.getItem('AOB_COLORMODE');
            if (themeMode != null) {
                setColorMode(themeMode);
                toggleColorMode(themeMode);
            }

            const storedValue = sessionStorage.getItem('CopyrightText');
            setCopyrightText(storedValue);

            if (storedValue == null) {
                const apiUrl = new URL(
                    process.env.REACT_APP_GET_BANK_INFO,
                    process.env.REACT_APP_BASE_URL,
                );

                let response = await ServerAPICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' });

                if (response !== null && response !== undefined) {
                    if (response["data"] && response["data"].length > 0) {
                        sessionStorage.setItem('CopyrightText', response.data[0].CopyrightText);
                        setCopyrightText(response.data[0].CopyrightText);
                    }
                }
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
        }
    }

    const changeColorMode = (colorMode) => {
        setColorMode(colorMode);
        toggleColorMode(colorMode);
    }

    const handleLightModeClick = (event) => {
        localStorage.setItem('AOB_COLORMODE', 'dark');
        setColorMode('dark');
        toggleColorMode('dark');
    };

    const handleDarkModeClick = (event) => {
        localStorage.setItem('AOB_COLORMODE', 'light');
        setColorMode('light');
        toggleColorMode('light');
    };

    useEffect(() => {

        let roleName = GetRoleNameOfUser(props?.userId ?? 0);
        setRoleName(roleName);

        loadConfig();
    }, []);

    return (
                <React.Fragment>

            <Toolbar sx={{ mb: 4, borderTop: 1, borderColor: '#6B7176', justifyContent: 'space-between', height: '150px', alignItems: 'flex-start' }}>
                <Typography color="text.primary" variant="caption" sx={{ mb: 0, pb: 0, pl: 0, pt: 1.5, }}>
                    {copyrightText}
                </Typography>
                <Box sx={{ pr: 3, pt: 1 }}>
                    <Stack direction="row">
                        <Box sx={{pr: 2, }}>
                            <Typography color="text.secondary" variant="body1" sx={{ }}>
                                {roleName}
                            </Typography>
                        </Box>
                        <Box>
                            {
                                colorMode == 'light' ?
                                    <Tooltip arrow title="Toggle Ligh/Dark Mode">
                                        <span>
                                            <IconButton sx={{ ml: 1, border: 0, borderColor: '#292929', p: 0, pr: 0, }} onClick={handleLightModeClick}>
                                                <WbSunnyIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    :
                                    <Tooltip arrow title="Toggle Ligh/Dark Mode">
                                        <span>
                                            <IconButton sx={{ ml: 1, border: 0, borderColor: '#292929', p: 0, pr: 0, }} onClick={handleDarkModeClick}>
                                                <DarkModeIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                            }
                            {/*<select style={{width: 100, height: 30}} className="form-select" id="cboColorMode" value={colorMode} onChange={(event) => changeColorMode(event.target.value)} >*/}
                            {/*    <option key={0} value={'light'}>Light</option>*/}
                            {/*    <option key={1} value={'dark'}>Dark</option>*/}
                            {/*</select>*/}
                        </Box>
                    </Stack>
                </Box>
            </Toolbar>
        </React.Fragment >

    )
}
export default Footer;

