import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Paper, Grid, Box, Container, Typography, Stack, Link, IconButton, Button, Divider } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu'
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
import Footer from './Footer';
import AutoLagoutTimer from '../global/IdleMonitor';
import ContactHistory from './AuditTrailReport/ContactHistory';
import EnrollmentHistories from './AuditTrailReport/EnrollmentHistory';
import TemplateHistory from './AuditTrailReport/TemplateHistory';
import SysuserHistory from './AuditTrailReport/SysuserHistory';
import RoleHistory from './AuditTrailReport/RoleHistory';
import RoleDefinitionHistory from './AuditTrailReport/RoleDefinitionHistory';

const AuditTrailReport = () => {

    let sections = {
        User: 1,
        Template: 2,
        Enrollment: 3,
        Sysuser: 4,
        Role: 5,
        RoleDefinition: 6
    };

    const location = useLocation();
    const [selectedSection, setSelectedSection] = useState(sections.User);

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="" disableGutters={true}>
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead">
                        <Box sx={{
                            pr: 0,
                            pl: 0, pt: 0
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" spacing={0} sx={{ pt: 0, pb: 0, }}>
                        <Grid item md={12} sx={{ pr: 0 }}>
                            <Box sx={{ pl: 0, pt: 0 }}>
                                <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 9, mr: 9, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                    <Typography variant="h6" color="text.primary" sx={{ fontSize: '20px', pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Audit Trail Report</Typography>
                                </Box>
                                <Box sx={{ }}>
                                    <Divider variant="fullWidth" component="div" sx={{ }} />
                                </Box>
                                <Box sx={{ pt: 3, pb: 2.5, ml: 9, mr: 9, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                    <Stack direction="column" sx={{}}>
                                        <Typography sx={{ pl: 0, fontWeight: 400, }} variant="body1" color="text.primary">
                                            The Audit Trail Report includes traceability for item-level inserts/edits/deletes.
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ pl: 0 }}>
                        <Grid item xs container direction="row" spacing={0} sx={{ pt: 1.5, pb: 4 }}>
                            <Grid item xs={12} md={3} sx={{}}>
                                <Box sx={{ ml: 9, minHeight: '600px', border: '1px solid #F5F5F5', borderTop: '2px solid #EBEBEB' }}>
                                    <Stack direction="column">
                                        <Divider variant="fullWidth" component="div" sx={{ }} />
                                        <Box sx={{ ml: 0, mr: 4, mt: 0, mb: 0, pl: 1.5 }} >
                                            <Button sx={{ width: '100%' }} onClick={() => setSelectedSection(sections.User)} >
                                                <Typography variant="body1" sx={{ width: '100%', textAlign: 'left', textTransform: 'none', fontWeight: 400 }} color="text.primary">User</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 0, mb: 0.5, mr: 0 }} />
                                        <Box sx={{ ml: 0, mr: 4, pl: 1.5, }} >
                                            <Button sx={{ mr: 0, width: '100%' }} onClick={() => setSelectedSection(sections.Sysuser)} >
                                                <Typography variant="body1" sx={{ width: '100%', textAlign: 'left', textTransform: 'none', fontWeight: 400 }} color="text.primary">User (Other Information)</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 0.5, mb: 0.5, mr: 0 }} />
                                        <Box sx={{ ml: 0, mr: 4, pl: 1.5 }} >
                                            <Button sx={{ mr: 0, width: '100%' }} onClick={() => setSelectedSection(sections.Role)} >
                                                <Typography variant="body1" sx={{ width: '100%', textAlign: 'left', textTransform: 'none', fontWeight: 400 }} color="text.primary">Role</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 0.5, mb: 0.5, mr: 0 }} />
                                        <Box sx={{ ml: 0, mr: 4, pl: 1.5 }} >
                                            <Button sx={{ mr: 0, width: '100%' }} onClick={() => setSelectedSection(sections.RoleDefinition)} >
                                                <Typography variant="body1" sx={{ width: '100%', textAlign: 'left', textTransform: 'none', fontWeight: 400 }} color="text.primary">Role Detail</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 0.5, mb: 0.5, mr: 0 }} />
                                        <Box sx={{ ml: 0, mr: 4, pl: 1.5 }} >
                                            <Button sx={{ mr: 0, width: '100%' }} onClick={() => setSelectedSection(sections.Enrollment)} >
                                                <Typography variant="body1" sx={{ width: '100%', textAlign: 'left', textTransform: 'none', fontWeight: 400 }} color="text.primary">Enrollment</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 0.5, mb: 0.5, mr: 0 }} />
                                        <Box sx={{ ml: 0, mr: 4, pl: 1.5 }} >
                                            <Button sx={{ mr: 0, width: '100%' }} onClick={() => setSelectedSection(sections.Template)} >
                                                <Typography variant="body1" sx={{ width: '100%', textAlign: 'left', textTransform: 'none', fontWeight: 400 }} color="text.primary">Template</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 0.5, mb: 0.5, mr: 0 }} />
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={9} sx={{ pr: 9, pt: 1 }}>
                                {
                                    (selectedSection === sections.User) ?
                                        <ContactHistory userId={userId} sessionId={sessionId} />
                                        :
                                        (selectedSection === sections.Role) ?
                                            <RoleHistory userId={userId} sessionId={sessionId} />
                                            :
                                        (selectedSection === sections.RoleDefinition) ?
                                            <RoleDefinitionHistory userId={userId} sessionId={sessionId} />
                                            :
                                        (selectedSection === sections.Enrollment) ?
                                            <EnrollmentHistories userId={userId} sessionId={sessionId} />
                                            :
                                            (selectedSection === sections.Template) ?
                                                <TemplateHistory userId={userId} sessionId={sessionId} />
                                                :
                                                (selectedSection === sections.Sysuser) ?
                                                    <SysuserHistory userId={userId} sessionId={sessionId} />
                                                    :
                                                    <div><h5>Invalid operation!!</h5></div>

                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default AuditTrailReport;