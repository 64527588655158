import React, { useState, useRef, useEffect, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Link, Stack, Toolbar, Box, Alert, FormControl, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import Button from '@mui/material/Button';
import Footer from './Footer';
import Grid from '@mui/material/Grid';
import { ErrorMessages } from '../common/ErrorMessages';
import { PreLoginMenu } from './PreLoginMenu';
import { ReactComponent as ReactLogo } from '../assets/images/ssb-horizontal-new.svg';
import AlertControl from './Controls/AlertControl';
import { GetUserCurrentDate, GetOSPlatform, GetBrowserName, GetCountryOfUser } from '../global/Helpers';
import { APICall, AddToSession, WriteToActivityLogWL } from '../global/Api';
import { IsError } from '../global/APIUtils';
//import clientlogo from '../assets/images/ssb-logo.png';
import clientlogo from '../assets/images/ssb-logo-lpl-blue.png';
import pwdimg from '../assets/images/ssw-otp.jpg';
import ClientLogoHeader from './ClientLogoHead'
import { ActivityLogEvent } from '../common/AOBEnum';

const OTPVerification = (props) => {

    const location = useLocation();
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [blurEmail, setBlurEmail] = useState('');
    const [counter, setCounter] = useState(120);
    const [runTimer, setRunTimer] = useState(true);
    const [resendTextDisp, setResendTextDisp] = useState('block');
    const [resendButtonDisp, setResendButtonDisp] = useState('none');
    const [disSubmitBtn, setDisSubmitBtn] = useState(false);

    const emailAddress = useRef(location.state.emailAddress);
    const [userId, setUserId] = useState(location.state.userId);
    const [sessionId, setSessionId] = useState(location.state.sessionId);
    const [otpRef, setOtpRef] = useState(location.state.otpRef);
    const atoken = useRef(location.state.atoken);
    const rtoken = useRef(location.state.rtoken);

    const OTPVerificationForm = useFormik({
        initialValues: {
            verificationCode: ''
        },
        validationSchema: yup.object({
            verificationCode: yup.string()
                .max(30, "Verification code - must be a string with a maximum length of 10")
                .required('Verification code - required.'),
        }),
        onSubmit: values => {
            //setEmail(values.verificationCode)
            handleSubmit(values);
        }
    });

    const handleSubmit = async (resetValues) => {

        let inputJson = {
            EmailAddress: emailAddress.current,
            LoginOTP: resetValues.verificationCode,
            LoginOTPRef: otpRef
        };


        setErrorMsg('');

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_VERIFY_LOGIN_OTP,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId, atoken.current);

            if (response != null && response != undefined) {


                if (response.meta.Success == '1') {

                    await WriteToActivityLogWL(ActivityLogEvent.LoginScreen, "OTP verification for login succeeded. User: '" + emailAddress.current + "'.");
                    await navigateToHome();
                }
                else {
                    await WriteToActivityLogWL(ActivityLogEvent.LoginScreen, "OTP verification for login failed. Reason: '" + response.Errors[0].Message + "'. Email address: '" + emailAddress.current + "'.");
                    setErrorMsg(response.Errors[0].Message);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const navigateToHome = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_SESSION_PARAMETERS,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId, atoken.current);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {
                    AddToSession(response, response.data[0].EmailAddress, atoken.current, rtoken.current);
                    navigate('/UserHome', { state: { userId: userId, sessionId: sessionId }, replace: true });
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

    }

    const login = (event) => {
        event.preventDefault();
        navigate('/AppLogin', { state: {}, replace: true });
    };

    useEffect(() => {

        if (emailAddress.current) {
            let arr = emailAddress.current.split("@");
            let len = arr[0].length;
            setBlurEmail(arr[0][0] + '*****' + (len > 1 ? arr[0][len - 2] : '') + arr[0][len - 1] + '@' + arr[1]);
        }

    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            setResetCounter();
        }, 1000);

        return () => {
            clearInterval(id);
        };
    });

    function setResetCounter() {
        if (runTimer) {
            if (parseInt(counter) == 0) {
                setRunTimer(false);
                setResendTextDisp('none');
                setResendButtonDisp('block');
                setDisSubmitBtn(true);
                return;
            }

            setCounter(counter - 1);
        }
    }

    const ResendCode = async (event) => {

        event.preventDefault();

        setErrorMsg('');

        try {

            setIsLoading(true);

            //let browserName = GetBrowserName();
            //let country = GetCountryOfUser();
            //let os = GetOSPlatform();
            let currentDate = GetUserCurrentDate();

            let inputJson = {
                EmailAddress: emailAddress.current,                
                UserLocationDate: currentDate
            };

            setErrorMsg('');

            const apiUrl = new URL(
                process.env.REACT_APP_SEND_LOGIN_OTP,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId, atoken.current);

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        let otpRef = response.data[0];
                        setOtpRef(otpRef);
                        setCounter(120);
                        setRunTimer(true);
                        setResendTextDisp('block');
                        setResendButtonDisp('none');
                        setDisSubmitBtn(false);
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth="lg" disableGutters={true}>
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper className="back-grid">
                    <ClientLogoHeader />
                    <Grid sx={{ pb: 5, pt: 7, minHeight: '600px'}} container>
                        <Grid item xs={12} sm={1}>
                        </Grid>
                        <Grid item xs={12} sm={8} sx={{ pl: 0, }}>
                            <Box sx={{ mx: 'auto', border: 0, borderColor: '#16191f', borderRadius: '6px', pl: 0, pt: 0, pb: 4 }}>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <Typography variant="h5" color="text.primary" sx={{ mb: 2, fontWeight: 500, letterSpacing: '0px', textTransform: 'none', lineHeight: 1.2, }}>
                                    Let's verify your account
                                </Typography>
                                <Typography variant="body3" color="text.primary" sx={{ fontWeight: 400, mb: 4, mt: 0.5 }}>Why? You've enabled 2-step verification for your account.</Typography>
                                <Box sx={{pt: 3}}>
                                    <Typography variant="body3" color="text.primary" sx={{ fontWeight: 600, mt: 3.5 }}>Enter verification code (Ref: {otpRef}) sent to your email {blurEmail}</Typography>
                                </Box>
                                <Box sx={{ pt: 4.5, pr: 35 }}>
                                    <form onSubmit={OTPVerificationForm.handleSubmit} id="frmOTPVerification" >
                                        <Box>
                                            <label htmlFor="verificationCode" className="form-label">Verification Code*</label>
                                            <input type="text" id="verificationCode" name="verificationCode" autoComplete="off" placeholder="Enter code here" className="form-control" value={OTPVerificationForm.values.verificationCode} onChange={OTPVerificationForm.handleChange} />
                                            {
                                                OTPVerificationForm.touched.verificationCode && OTPVerificationForm.errors.verificationCode ? (<p className="text-danger">{OTPVerificationForm.errors.verificationCode}</p>) : null
                                            }
                                        </Box>
                                        <Typography variant="body1" color="text.primary" sx={{ pt: 2, display: resendTextDisp }}>
                                            Resend code in {counter}
                                        </Typography>
                                        <Box sx={{ pt: 1.5, display: resendButtonDisp }}>
                                            <Button sx={{ textTransform: 'none' }} onClick={(e) => ResendCode(e)}>
                                                <Typography variant="body1" sx={{ color: '#002d72' }}>
                                                    Resend code
                                                </Typography>
                                            </Button>
                                        </Box>
                                        <Box sx={{ mt: 4 }}>
                                            <Button type="submit" variant="contained" size="small" sx={{  borderRadius: '20px', padding: '0.3rem 2.5rem', textTransform: 'none'  }}>
                                                Verify
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                                <Box sx={{ pt: 3.5 }}>
                                    <Link underline="hover" onClick={login} sx={{ textTransform: 'none', color: 'Link.main' }}>Back to Login</Link>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Footer />
                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default OTPVerification;