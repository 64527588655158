import React, { useEffect, useState, useRef } from 'react';
import { Chip, Box, TableContainer, Dialog, Divider, Checkbox, DialogTitle, Link, Stack, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { UploadUserFiles } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { APICall, WriteToActivityLog } from '../global/Api';
import { GetLoggedInUserEmail } from '../global/APIUtils';
import { EnrollmentStatus, ActivityLogEvent } from '../common/AOBEnum';
import CheckIcon from '@mui/icons-material/Check';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { GetAttachedFile } from '../global/EnrollmentHelper';

const DocuSignStatusDlg = (props) => {

    const { onClose, open, onOkClick, ...other } = props;
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [enrmntid, setEnrmntid] = useState(props.enrmntid || -1);
    const [attachmentid, setAttachmentid] = useState(props.attachmentid || -1);
    const [updateParent, setUpdateParent] = useState(false);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const [docuSignEmailSts, setDocuSignEmailSts] = useState(null);

    const handleClose = (event) => {
        onClose();
    };

    const loadDetails = async () => {

        try {

            setIsLoading(true);

            await WriteToActivityLog(ActivityLogEvent.DocuSign, "'DocuSign status' dialog opened. Application name '" + props.enrmntNm + "'.", userId, sessionId);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_DOCUSIGN_STATUS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentid)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response["data"] && response["data"].length > 0) {
                    setDocuSignEmailSts(response["data"]);
                }
                else {

                    let apiErrors = response["Errors"];

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }
                    else {
                        setDocuSignEmailSts(null);
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    const downloadSignedAttachment = async (e, attachmentId, fileNm) => {

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_SIGNED_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);
            setUpdateParent(true);

            await WriteToActivityLog(ActivityLogEvent.DocuSign, "Downloaded signed file. Application name '" + props.enrmntNm + "'. File name: '" + fileNm + "'.", userId, sessionId);
        }
        catch (ex) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const downloadDocusignFile = async (e, attachmentId, fileNane) => {

        try {

            let inputJson = {
                EnrmntId: enrmntid,
                AttchmntId: attachmentId
            };

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_DOCUSIGN_FILE,
                process.env.REACT_APP_BASE_URL,
            );


            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.DocumentSaved === "1") {

                    await WriteToActivityLog(ActivityLogEvent.DocuSign, "Downloaded signed file from DocuSign. Application name '" + props.enrmntNm + "'. File name: '" + fileNane + "'.", userId, sessionId);

                    setUpdateParent(true);
                    setSuccessMsg('Signed file named as ' + fileNane + ' downloaded successfully.');
                    setDocuSignEmailSts(response["data"]);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    //const handleJtOwnrSignReqChange = (event) => {
    //    setJntOwnerEmailChecked(event.target.checked);
    //    setIsJntOwnerSignReq(event.target.checked);
    //};

    useEffect(() => {
        loadDetails();
    }, []);

    const handleOk = () => {
        onClose();

        onOkClick(updateParent);
    }

    return (
        <Dialog open={open} keepMounted maxWidth={'sm'} scroll="paper">
            {/*<LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >*/}
            <DialogTitle sx={{}}>
                <Box sx={{ pl: 0.5, pt: 1 }}>
                    <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                        DocuSign status
                    </Typography>
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: (theme) => theme.palette.Icon.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
                <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >

                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <AlertControl Message={successMsg} severity="success" color="success" icon={true} />
                    <Box sx={{pl: 2, pr: 2}}>
                    <Grid container>
                            <Grid item md={11} sx={{ pb: 2 }}>
                                <Box sx={{pb: 1}}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400, }}>This is the current DocuSign status of the file(s) and recipient(s). The status will be updated as the recipient(s) perform actions on the file(s). </Typography>
                                </Box>
                                    {/*<Divider light />*/}
                        </Grid>
                        <Grid item md={11} sx={{pb: 1}}>
                            {
                                docuSignEmailSts ?
                                    
                                        <Table aria-label="simple table" sx={{ border: '1px solid #e0e0e0', pt: 2, pl: 1, pb: 1 }}>
                                            <TableBody>{ }
                                                {
                                                    docuSignEmailSts.map((item) => (
                                                        <React.Fragment>
                                                            <TableRow sx={{}}>
                                                                <TableCell colSpan="2" scope="row" sx={{ pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" sx={{ fontWeight: 500 }}>
                                                                        {item.FileName}<Chip sx={{ml: 1.5}} label={item.SignStatus} />
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{}}>
                                                                <TableCell scope="row" sx={{ width: '150px', pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                        Signed file:
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell scope="row" sx={{ pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Button disabled={item.SignStatus.toLowerCase() === 'completed' ? false : true} onClick={(e) => downloadDocusignFile(e, item.AttachementId, item.FileName)} variant="contained" size="large" style={{ display: item.IsSignedAttachmentDownloaded ? 'none' : 'block', boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2rem', textTransform: 'none', outline: 'none', }}>
                                                                        Download
                                                                        {/*<Typography variant="caption" color="#002d72" sx={{ fontSize: '11px', fontWeight: 500, letterSpacing: '1px' }} >Download</Typography>*/}
                                                                    </Button>
                                                                    <Link underline="hover" variant="body2" sx={{ display: item.IsSignedAttachmentDownloaded ? 'block' : 'none', mb: 0, fontWeight: 300, color: 'Link.main' }} onClick={(e) => downloadSignedAttachment(e, item.AttachementId, item.FileName)}>{item.FileName}</Link>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{}}>
                                                                <TableCell scope="row" sx={{ width: '150px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                        Signer1:
                                                                    </Typography>
                                                                    </TableCell>
                                                                    <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                            {item.Signer1Email}
                                                                        </Typography>
                                                                    </TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{}}>
                                                                <TableCell scope="row" sx={{ width: '100px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                        DocuSign Status:
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Stack direction="column">
                                                                        <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                            {item.Signer1Status}
                                                                        </Typography>
                                                                        <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                            {
                                                                                item.Signer1Status === 'declined' ? 'Reason: ' + item.Signer1DeclineReason : null
                                                                            }
                                                                        </Typography>
                                                                    </Stack>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{}}>
                                                                <TableCell scope="row" sx={{ width: '100px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                        DocuSign Mail:
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                        {item.Signer1EmailSentAt === 'not sent' ? item.Signer1EmailSentAt : 'Last sent on ' + item.Signer1EmailSentAt}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            {
                                                                item.Signer2Email !== '' ?
                                                                    <React.Fragment>
                                                                        <TableRow sx={{}}>
                                                                            <TableCell scope="row" sx={{ width: '150px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    Signer2:
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer2Email}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow sx={{}}>
                                                                            <TableCell scope="row" sx={{ width: '100px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    DocuSign Status:
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                            <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                {item.Signer2Status}
                                                                            </Typography>
                                                                            <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                            {
                                                                                item.Signer2Status === 'declined' ? 'Reason: ' + item.Signer2DeclineReason : null
                                                                            }
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                        <TableRow sx={{}}>
                                                                            <TableCell scope="row" sx={{ width: '100px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    DocuSign Mail:
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer2EmailSentAt === 'not sent' ? item.Signer2EmailSentAt : 'Last sent on ' + item.Signer2EmailSentAt}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </React.Fragment>
                                                                    : ''
                                                            }
                                                            {
                                                                item.Signer3Email !== '' ?
                                                                    <React.Fragment>
                                                                        <TableRow sx={{}}>
                                                                            <TableCell scope="row" sx={{ width: '150px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    Signer{item.Signer2Email !== '' ? 3 : 2 }:
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer3Email}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow sx={{}}>
                                                                            <TableCell scope="row" sx={{ width: '100px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    DocuSign Status:
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer3Status}
                                                                                </Typography>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {
                                                                                        item.Signer3Status === 'declined' ? 'Reason: ' + item.Signer3DeclineReason : null
                                                                                    }
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow sx={{}}>
                                                                            <TableCell scope="row" sx={{ width: '100px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    DocuSign Mail:
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer3EmailSentAt === 'not sent' || item.Signer3EmailSentAt === '' ? 'not sent' : 'Last sent on ' + item.Signer3EmailSentAt}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </React.Fragment>
                                                                    : ''
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    
                                    : ''
                            }
                        </Grid>
                        </Grid>
                    </Box>
                    </LoadingOverlay>

                </DialogContent>
                <DialogActions sx={{ mr: 2.5, pb: 1.5 }}>
                    <Button variant="contained" onClick={handleOk} sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }}>
                        Ok
                    </Button>
                </DialogActions>
            {/*</LoadingOverlay>*/}
        </Dialog>
    );
}

export default DocuSignStatusDlg;
