import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import Badge from '@mui/material/Badge';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import { AOBSystemProcess } from '../common/AOBEnum';
import { GetAccessControl, IsLoggedInUserProspectUser } from '../global/APIUtils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { GetLoggedInUserId } from '../global/APIUtils';

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

export function DrawerAppBar(props) {

    const x = 'inline-block';

    const [prospectDashboardMenuDisp, setProspectDashboardMenuDisp] = useState('none');
    const [applDashboardMenuDisp, setApplDashboardMenuDisp] = useState('none');
    const [templateMenuDisp, setTemplateMenuDisp] = useState('none');
    const [disUsersMenu, setDisUsersMenu] = useState(true);
    const [disRolesMenu, setDisRolesMenu] = useState(true);
    const [disActLogMenu, setDisActLogMenu] = useState(true);
    const [disSysOptMenu, setDisSysOptMenu] = useState(false);
    const [disSysMsgMenu, setDisSysMsgMenu] = useState(true);
    const [disAuditTrailRptMenu, setDisAuditTrailRptMenu] = useState(true);
    const [disErrLogMenu, setDisErrLogMenu] = useState(true);
    const [disTraceLogMenu, setDisTraceLogMenu] = useState(true);
    const [moreMenuDisp, setMoreMenuDisp] = useState('none');
    const [userId, setUserId] = useState((!props.userId || props.userId == -1) ? (GetLoggedInUserId() != null && GetLoggedInUserId() != undefined) ? GetLoggedInUserId() : -1 : props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleUsersMenuClick = () => {
        navigate('/UserManagement', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };
    const handleRolesMenuClick = () => {
        navigate('/RoleManagement', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };

    const handleSystemOptionsMenuClick = () => {
        navigate('/SystemConfig', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };

    const auditReportMenuClick = () => {
        navigate('/AuditTrailReport', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };

    const handleErrorLogMenuClick = () => {
        navigate('/ExceptionDetails', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };

    const handleActivityLogMenuClick = () => {
        navigate('/ActivityLog', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };

    let navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    let sessions = useSelector((state) => state.sessionMgmnt);

    useEffect(() => {

        if (!userId || userId == -1) {
            navigate('/AppLogin', { state: {}, replace: true });
            return;
        }

        let accessControl = GetAccessControl(userId, sessionId);
        let isProspectUser = IsLoggedInUserProspectUser(userId, sessionId);

        let accessControlArray = accessControl.split("|");

        SetProspectDashboardMenuDisplay(accessControlArray, isProspectUser);
        SetApplDashboardMenuDisplay(accessControlArray, isProspectUser);
        SetTemplateMenuDisplay(accessControlArray, isProspectUser);
        SetUsersMenuDisplay(accessControlArray, isProspectUser);
        SetRoleMenuDisplay(accessControlArray, isProspectUser);
        SetActivityLogMenuDisplay(accessControlArray, isProspectUser);
        SetSystemOptionMenuDisplay(accessControlArray, isProspectUser);
        SetSystemMessageMenuDisplay(accessControlArray, isProspectUser);
        SetAuditTrailRptMenuDisplay(accessControlArray, isProspectUser);
        SetErrorLogMenuDisplay(accessControlArray, isProspectUser);
        SetTraceLogMenuDisplay(accessControlArray, isProspectUser);
        SetMoreMenuDisplay(accessControlArray, isProspectUser);

    }, []);

    const SetProspectDashboardMenuDisplay = (accessControlArray, isProspectUser) => {

        if (isProspectUser)
            setProspectDashboardMenuDisp('inline-block');
        else
            setProspectDashboardMenuDisp('none');
    }

    const SetApplDashboardMenuDisplay = (accessControlArray, isProspectUser) => {

        if (isProspectUser) {
            setApplDashboardMenuDisp('none');
            return;
        }

        const found = accessControlArray.filter(element => element == AOBSystemProcess.ApplicationMaintenance
            || element == AOBSystemProcess.ViewApplication || element == AOBSystemProcess.ExportApplication
            || element == AOBSystemProcess.ApproveApplication || element == AOBSystemProcess.PublishApplication
            || element == AOBSystemProcess.DocuSignDocUpload || element == AOBSystemProcess.AllProcess);

        if (found !== undefined && found.length > 0)
            setApplDashboardMenuDisp('inline-block');
        else
            setApplDashboardMenuDisp('none');
    }

    const SetTemplateMenuDisplay = (accessControlArray, isProspectUser) => {

        if (isProspectUser) {
            setTemplateMenuDisp('none');
            return;
        }

        const found = accessControlArray.filter(element => element == AOBSystemProcess.TemplateMaintenance || element == AOBSystemProcess.PublishTemplate
            || element == AOBSystemProcess.ViewAllTemplates || element == AOBSystemProcess.AllProcess);

        if (found !== undefined && found.length > 0)
            setTemplateMenuDisp('inline-block');
        else
            setTemplateMenuDisp('none');
    }

    const SetUsersMenuDisplay = (accessControlArray, isProspectUser) => {

        const found = accessControlArray.filter(element => element == AOBSystemProcess.UserMaintenance || element == AOBSystemProcess.AllProcess || element == AOBSystemProcess.DeleteUser);

        if (found !== undefined && found.length > 0)
            setDisUsersMenu(false);
        else
            setDisUsersMenu(true);
    }

    const SetRoleMenuDisplay = (accessControlArray, isProspectUser) => {

        const found = accessControlArray.filter(element => element == AOBSystemProcess.RoleMaintenance || element == AOBSystemProcess.AllProcess);

        if (found !== undefined && found.length > 0)
            setDisRolesMenu(false);
        else
            setDisRolesMenu(true);
    }

    const SetActivityLogMenuDisplay = (accessControlArray, isProspectUser) => {

        const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewActivityLog || element == AOBSystemProcess.AllProcess);

        if (found !== undefined && found.length > 0) {
            setDisActLogMenu(false);
        }
        else {
            setDisActLogMenu(true);
        }
    }

    const SetSystemOptionMenuDisplay = (accessControlArray, isProspectUser) => {
        const found = accessControlArray.filter(element => element == AOBSystemProcess.SystemParamMaintenance || element == AOBSystemProcess.AllProcess);
        if (found !== undefined && found.length > 0)
            setDisSysOptMenu(false);
        else
            setDisSysOptMenu(true);
    }

    const SetSystemMessageMenuDisplay = (accessControlArray, isProspectUser) => {

        //const found = accessControlArray.filter(element => element == AOBSystemProcess.SystemMessage);

        //if (found !== undefined && found.length > 0)
        //    setDisSysMsgMenu(false);
        //else
        //    setDisSysMsgMenu(true);
    }

    const SetAuditTrailRptMenuDisplay = (accessControlArray, isProspectUser) => {

        const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewAuditTrailReport || element == AOBSystemProcess.AllProcess);

        if (found !== undefined && found.length > 0)
            setDisAuditTrailRptMenu(false);
        else
            setDisAuditTrailRptMenu(true);
    }

    const SetErrorLogMenuDisplay = (accessControlArray, isProspectUser) => {

        //const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewErrorLog);

        //if (found !== undefined && found.length > 0)
        //    setDisErrLogMenu(false);
        //else
        //    setDisErrLogMenu(true);
        const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewErrorLog || element == AOBSystemProcess.AllProcess);

        if (found !== undefined && found.length > 0)
            setDisErrLogMenu(false);
        else
            setDisErrLogMenu(true);
    }

    const SetTraceLogMenuDisplay = (accessControlArray, isProspectUser) => {

        //const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewTraceLog);

        //if (found !== undefined && found.length > 0)
        //    setDisTraceLogMenu(false);
        //else
        //    setDisTraceLogMenu(true);
    }

    const SetMoreMenuDisplay = (accessControlArray, isProspectUser) => {

        const found = accessControlArray.filter(element => element == AOBSystemProcess.UserMaintenance
            || element == AOBSystemProcess.RoleMaintenance || element == AOBSystemProcess.SystemParamMaintenance
            || element == AOBSystemProcess.ViewActivityLog || element == AOBSystemProcess.ViewErrorLog
            || element == AOBSystemProcess.ViewTraceLog || element == AOBSystemProcess.ViewAuditTrailReport
            || element == AOBSystemProcess.SystemMessage);

        if (found !== undefined && found.length > 0)
            setMoreMenuDisp('inline-block');
        else
            setMoreMenuDisp('none');
    }

    const SetProspectDasboardMenuClick = () => {
        navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const ApplMenuClick = () => {
        navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const EmailMenuClick = () => {
        navigate('/EmailMessages', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const ProfileMenuClick = () => {
        navigate('/UserProfileSetting', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const TemplateMenuClick = () => {
        navigate('/TemplateList', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                MUI
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar component="nav" position="relative" color="transparent" elevation={0} sx={{ p: 0, m: 0 }}>
                    <Toolbar disableGutters={true} sx={{ pl: 2, pr: 0.5, justifyContent: 'flex-end' }} >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/*<Stack direction="column" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>*/}
                        {/*    <Typography variant="h4" sx={{ fontWeight: 500, lineHeight: 1.1 }} color="text.primary">Account Onboarding</Typography>*/}
                        {/*    <Typography variant="body2" className="mt-3" color="text.secondary">Smarter. And so straightforward. Start saving for something great.</Typography>*/}
                        {/*</Stack>*/}
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Button variant="text" sx={{ ml: 0, mr: 1, pt: 0, display: prospectDashboardMenuDisp }} onClick={() => SetProspectDasboardMenuClick()}>
                                <Typography className="" variant="body1" sx={{ textTransform: 'none', letterSpacing: '0px', fontWeight: 400, pt: 0, }} color="text.primary">Application</Typography>
                            </Button>
                            <Button variant="text" sx={{ ml: 0, pt: 0, mr: 1, display: applDashboardMenuDisp }} onClick={() => ApplMenuClick()}>
                                <Typography className="" variant="body1" sx={{ textTransform: 'none', letterSpacing: '0px', fontWeight: 400, pt: 0, }} color="text.primary">Enrollment</Typography>
                            </Button>
                            <Button variant="text" sx={{ ml: 0, pt: 0, mr: 1, display: templateMenuDisp }} onClick={() => TemplateMenuClick()}>
                                <Typography className="" variant="body1" sx={{ textTransform: 'none', letterSpacing: '0px', fontWeight: 400, pt: 0, }} color="text.primary">Template</Typography>
                            </Button>
                            {/*<Button sx={{ ml: 0, mr: 3, display: 'inline-block' }} onClick={() => EmailMenuClick()}>*/}
                            {/*    <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 500}} color="text.secondary">Email</Typography>*/}
                            {/*</Button>*/}
                            <Button variant="text" sx={{ ml: 0, pt: 0, mr: 1.5, display: 'inline-block' }} onClick={() => ProfileMenuClick()}>
                                <Typography className="" variant="body1" sx={{ textTransform: 'none', letterSpacing: '0px', fontWeight: 400, pt: 0, }} color="text.primary">Profile</Typography>
                            </Button>
                            <Button sx={{ ml: -3, mr: 3.5, p: 0, display: moreMenuDisp }} onClick={handleClick}>
                                <KeyboardArrowDownIcon color="secondary.main" sx={{ p: 0, fontSize: 28, color: 'Icon.main' }} />
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>

            </Box>
            <Menu
                anchorEl={anchorEl}
                id="more-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem sx={{ ml: 0.5, mt: 1.5, mb: 0 }} onClick={handleUsersMenuClick} disabled={disUsersMenu} >
                    <ListItemIcon sx={{ ml: 1 }}>
                        <PeopleOutlinedIcon sx={{ fontSize: 18, color: 'Icon.main' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'none', ml: 0.5, mr: 6 }} color="text.primary">Users</Typography>
                </MenuItem>
                <MenuItem sx={{ ml: 0.5, mt: 0.5, mb: 0 }} onClick={handleRolesMenuClick} disabled={disRolesMenu} >
                    <ListItemIcon sx={{ ml: 1 }}>
                        <ManageAccountsOutlinedIcon sx={{ fontSize: 18, color: 'Icon.main' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'none', ml: 0.5, mr: 6 }} color="text.primary">User roles</Typography>
                </MenuItem>
                <Divider variant="fullWidth" component="div" sx={{}} />
                <MenuItem sx={{ ml: 0.5, mt: 0.5, mb: 0 }} onClick={handleActivityLogMenuClick} disabled={disActLogMenu} >
                    <ListItemIcon sx={{ ml: 1 }}>
                        <RecentActorsOutlinedIcon sx={{ fontSize: 18, color: 'Icon.main' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'none', ml: 0.5, mr: 6 }} color="text.primary">User activity log</Typography>
                </MenuItem>
                <Divider variant="fullWidth" component="div" sx={{}} />
                <MenuItem sx={{ ml: 0.5, mt: 0.5, mb: 0 }} onClick={handleSystemOptionsMenuClick} disabled={disSysOptMenu} >
                    <ListItemIcon sx={{ ml: 1 }}>
                        <SettingsSuggestOutlinedIcon sx={{ fontSize: 18, color: 'Icon.main' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'block', ml: 0.5, mr: 6 }} color="text.primary">System options</Typography>
                </MenuItem>
                {/*<MenuItem onClick={handleClose} disabled={disSysMsgMenu} >*/}
                {/*    <ListItemIcon sx={{ ml: 2 }}>*/}
                {/*        <MessageOutlinedIcon width="24px" height="24px" sx={{ color: '#333333' }} />*/}
                {/*    </ListItemIcon>*/}
                {/*    <Typography className="me-5" variant="body3" sx={{ textTransform: 'none', ml: 0 }} color="text.primary">System messages</Typography>*/}
                {/*</MenuItem>*/}
                {/*<Divider />*/}
                <Divider variant="fullWidth" component="div" sx={{}} />
                <MenuItem sx={{ ml: 0.5, mt: 0.5, mb: 0 }} onClick={auditReportMenuClick} disabled={disAuditTrailRptMenu} >
                    <ListItemIcon sx={{ ml: 1 }}>
                        <SummarizeOutlinedIcon sx={{ fontSize: 18, color: 'Icon.main' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'none', ml: 0.5, mr: 6 }} color="text.primary">Audit trail report</Typography>
                </MenuItem>
                {/*<Divider />*/}
                <Divider variant="fullWidth" component="div" sx={{}} />
                <MenuItem sx={{ ml: 0.5, mt: 0.5, mb: 4 }} onClick={handleErrorLogMenuClick} disabled={disErrLogMenu} >
                    <ListItemIcon sx={{ ml: 1 }}>
                        <ReportProblemOutlinedIcon sx={{ fontSize: 18, color: 'Icon.main' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'none', ml: 0.5, mr: 6 }} color="text.primary">Error log</Typography>
                </MenuItem>
                {/*    <MenuItem onClick={handleClose} disabled={disTraceLogMenu} >*/}
                {/*        <ListItemIcon sx={{ ml: 2 }}>*/}
                {/*            <TravelExploreOutlinedIcon width="24px" height="24px" sx={{ color: '#333333' }} />*/}
                {/*        </ListItemIcon>*/}
                {/*        <Typography className="me-5" variant="body3" sx={{ textTransform: 'none', ml: 0 }} color="text.primary">Trace log</Typography>*/}
                {/*    </MenuItem>*/}
            </Menu>
        </>
    );
}

