import React, { useEffect, useState } from 'react';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import jwt_decode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addSession } from '../redux/sessionMgmnt';
import { PreLoginMenu } from './PreLoginMenu';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import moment from 'moment';
//import AlertText from './Controls/AlertText';
import LoginFeature from './LoginFeature';
import Footer from './Footer';
import OverlaySpinner from './Controls/OverlaySpinner';
import { CallLoginAPI, AddLoginInfoInSession, IsError } from '../global/APIUtils';
import { authenticate, AddToSession } from '../global/Api';
import { RedirectToHome, GetUserCurrentDate, GetOSPlatform, GetBrowserName, GetCountryOfUser, GetLoginSatusStr } from '../global/Helpers';
import { LoginStatus, ActivityLogEvent } from '../common/AOBEnum';
import { ErrorMessages } from '../common/ErrorMessages';
import Button from '@mui/material/Button';
import { Typography, Link, card, CardContent, Box, FormControl, Toolbar, Stack, Divider } from '@mui/material';
import { ChevronRight } from 'react-bootstrap-icons';
import { Paper } from '@mui/material';
import AlertControl from './Controls/AlertControl';
import { AOBSystemProcess } from '../common/AOBEnum';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
//import pwdimg from '../assets/images/login_hero.jpg';
import pwdimg from '../assets/images/LoginHeroBG.jpg';
//import clientlogo from '../assets/images/demo_bank_logo.jpg';
//import clientlogo from '../assets/images/bank_logo.jpg';
import clientlogo from '../assets/images/ssb-logo-lpl-blue.png';
//import pwdimg from '../assets/images/864687.jpg';
//import clientlogo from '../assets/images/ssb-logo1.jpg';
import Image from 'material-ui-image'
import { ServerAPICall, APICall, WriteToActivityLog, WriteToActivityLogWL } from '../global/Api';
import ClientLogoHeader from './ClientLogoHead'

//import AspectRatio from '@mui/joy/AspectRatio';

export function AppLogin() {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [showCaptcha, setShowCaptcha] = useState(parseInt(process.env.REACT_APP_DIAPLAY_CAPTCHA_AFTER));
    //const captchaThreshold = useState(process.env.REACT_APP_DIAPLAY_CAPTCHA_AFTER);
    const [failedLoginCount, setFailedLoginCount] = useState(1);
    const [isForceLogin, setIsForceLogin] = useState(false);
    const [loginStatusMsg, setLoginStatusMsg] = useState('');

    const [imageUrl, setImageUrl] = useState(null);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const search = useLocation().search;
    let logoutReason = new URLSearchParams(search).get("reason");

    useEffect(() => {

        if (failedLoginCount > showCaptcha) { loadCaptchaEnginge(6); }
    }, [failedLoginCount]);

    const loadLoginHeroImage = async () => {
        try {

            setIsLoading(true);

            let apiImageUrl = new URL(
                process.env.REACT_APP_GET_BANK_RESOURCE,
                process.env.REACT_APP_BASE_URL,
            );

            apiImageUrl.searchParams.set('resourceType', 3);

            let response = await ServerAPICall({ url: apiImageUrl.href, method: 'GET', contentType: 'application/json', responseType: 'blob' });

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }
            }
        }
        catch (err) {
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {

        if (logoutReason != null) {

            if (logoutReason.toUpperCase() == 'SESSION_NOTFOUND') {
                setLoginStatusMsg('Session has expired or could not be found. Please try login again.');
            }
            else if (logoutReason.toUpperCase() == 'SESSION_INVALID') {
                setLoginStatusMsg('You are logged on from some other place using the same login which prevents you using this site. Please try Log in again.');
            }
            else if (logoutReason.toUpperCase() == 'SESSION_EXISTED') {
                setLoginStatusMsg('You were logged in from some other place using the same login which prevents you using this site. Please try Log in again.');
            }
        }

        loadLoginHeroImage();

    }, []);

    const handleLoginError = async (responseMsg, userEmail) => {

        setIsForceLogin(false);

        if (responseMsg.data[0].UserLoginStatus === LoginStatus.AccountNotActivated) {
            setFailedLoginCount(0);
            setLoginStatusMsg(ErrorMessages.AccountNotActivated);
        }
        else if (responseMsg.data[0].UserLoginStatus === LoginStatus.AllreadyLoggedIn) {
            setFailedLoginCount(0);
            setIsForceLogin(true);
            setLoginStatusMsg(ErrorMessages.MultipleLoginMsg);
        }
        else if (responseMsg.data[0].UserLoginStatus === LoginStatus.Locked) {
            setFailedLoginCount(0);
            setLoginStatusMsg(ErrorMessages.AccountLocked);
        }
        else if (responseMsg.data[0].UserLoginStatus === LoginStatus.InvalidCredential) {
            setFailedLoginCount(responseMsg.data[0].NoOfTry);
            setLoginStatusMsg(ErrorMessages.InvalidCredential);
        }
        else if (responseMsg.data[0].UserLoginStatus === LoginStatus.InactiveUser) {
            setFailedLoginCount(0);
            setLoginStatusMsg(ErrorMessages.InactiveUser);
        }
        else {
            setFailedLoginCount(failedLoginCount + 1);
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }

        try {
            await WriteToActivityLogWL(ActivityLogEvent.LoginFail, "Reason: " + GetLoginSatusStr(responseMsg.data[0].UserLoginStatus) + ". Email address: " + userEmail);
        }
        catch (err) {
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
    }

    const isTwoFactorEnabled = async (userId, sessionId, atoken) => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_USER_PREFERENCE,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId, atoken);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {
                    if (response.data[0].IsTwoStepVerifySet) {
                        return true;
                    }
                }
            }
        }
        catch (err) {
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        return false;
    }

    const validateLogin = async (loginValues) => {

        try {

            setLoginStatusMsg("");
            setIsLoading(true);

            let response = await authenticate(loginValues.userid, loginValues.password, isForceLogin);

            if (response !== null && response != undefined) {
                if (response.data[0].Access_Token !== null && response.data[0].Access_Token !== undefined) {

                    let TFEnabled = await isTwoFactorEnabled(response.data[0].UserID, response.data[0].SessionID, response.data[0].Access_Token)
                    if (TFEnabled) {
                        await WriteToActivityLogWL(ActivityLogEvent.LoginScreen, "2-Factor enabled for login. User: " + loginValues.userid);
                        sendOTP(response.data[0].UserID, response.data[0].SessionID, response.data[0].Access_Token, response.data[0].Refresh_Token);
                        await WriteToActivityLogWL(ActivityLogEvent.LoginScreen, "Login OTP sent. User: " + loginValues.userid);
                    }
                    else {
                        AddToSession(response, loginForm.values.userid);
                        await WriteToActivityLog(ActivityLogEvent.LoginSuccess, "User logged in. User: " + loginValues.userid, response.data[0].UserID, response.data[0].SessionID);
                        navigate('/UserHome', { state: { userId: response.data[0].UserID, sessionId: response.data[0].SessionID }, replace: true });
                    }
                }
                else {
                    await handleLoginError(response, loginValues.userid);
                }
            }
        }
        catch (err) {
            console.log(err);
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const sendOTP = async (userId, sessionId, atoken, rtoken) => {

        //let browserName = GetBrowserName();
        //let country = GetCountryOfUser();
        //let os = GetOSPlatform();
        let currentDate = GetUserCurrentDate();

        let inputJson = {
            EmailAddress: loginForm.values.userid,
            UserLocationDate: currentDate
        };

        setLoginStatusMsg('');

        const apiUrl = new URL(
            process.env.REACT_APP_SEND_LOGIN_OTP,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId, atoken);

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setLoginStatusMsg(response.Errors[0].Message);
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        let otpRef = response.data[0];
                        navigate('/OTPVerification', { state: { emailAddress: loginForm.values.userid, userId: userId, sessionId: sessionId, otpRef: otpRef, atoken: atoken, rtoken: rtoken }, replace: true });
                    }
                    else {
                        setLoginStatusMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }
    const loginForm = useFormik({
        initialValues: {
            userid: '',
            password: ''
        },
        validationSchema: yup.object({
            userid: yup.string()
                .email('Invalid Email address')
                .required('Email - Required'),
            password: yup.string()
                .required('Password - Required')
        }),
        onSubmit: values => {

            let user_captcha = '';

            if (failedLoginCount > 1 && failedLoginCount > showCaptcha) {
                user_captcha = document.getElementById('user_captcha_input').value;

                if (validateCaptcha(user_captcha, false) === true) {
                    validateLogin(values);
                }
                else {
                    setLoginStatusMsg('Captcha does not match.');
                    document.getElementById('user_captcha_input').value = "";
                }
            }
            else {
                validateLogin(values);
            }
        },
    });

    const forgetPassword = (event) => {
        event.preventDefault();
        navigate('/ForgetPassword', { state: {}, replace: true });
    }

    const Register = (event) => {
        event.preventDefault();
        navigate('/UserRegistration', { state: {}, replace: true });
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}} disableGutters={true}>
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper className="back-grid" elevation={0}
                    sx={{
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 0 },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            backgroundColor: 'rgba(238, 238, 238, 0.08)', opacity: 1
                        }}
                    />
                    <ClientLogoHeader />
                    <Box>
                        <Grid sx={{ pb: 5, pt: 5, }} container>
                        <Grid item xs={12} sm={8}>
                            <Box sx={{ background: '#f4f4f4', ml: 7.5, mr: 4, border: '1px solid #575C60', borderRadius: '12px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 24px -12px' }}>
                                {imageUrl ?
                                        <img src={URL.createObjectURL(imageUrl)} height="480" loading="lazy" />
                                    :
                                        <img sx={{}} src={pwdimg} height="480" loading="lazy" />
                                }
                                {/*<img sx={{}} src={pwdimg} height="450" loading="lazy" />*/}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{}}>
                            <Grid container>
                                <Grid item sm={12} sx={{ pl: 1, pr: 4, pt: 1.5 }}>
                                    <Box sx={{ opacity: 1, borderLeft: '0px solid rgba(0, 0, 0, 0.14)', pl: 0, pr: 7, pt: 0.5, pb: 0, mb: 0, position: 'relative', borderRadius: '10px' }}>
                                            <Typography variant="h4" color="text.secondary" sx={{ fontSize: '22px', mb: 0, fontWeight: 400, letterSpacing: '-1px', textTransform: 'none', lineHeight: 1, }}>
                                            Digital
                                        </Typography>
                                        <Typography variant="h4" color="text.secondary" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.17)', fontSize: '30px', pt: 1, mb: 0, pb: 1, fontWeight: 500, letterSpacing: '-1px', textTransform: 'none', lineHeight: 1.25 }}>
                                             Account Onboarding
                                        </Typography>
                                        <Divider />
                                        <Typography variant="h6" color="text.secondary" sx={{ fontSize: '20px', mt: 2, mb: 0, fontWeight: 400, letterSpacing: '-1px', textTransform: 'none' }}>
                                            Sign in to your account
                                        </Typography>
                                    </Box>
                                    <Box sx={{ opacity: 1, borderLeft: '0px solid rgba(0, 0, 0, 0.14)', pl: 0, pr: 5, pt: 0, pb: 0, }}>
                                        <AlertControl Message={loginStatusMsg} severity="error" color="error" icon={true} />
                                    </Box>
                                    <Box sx={{ opacity: 1, borderLeft: '0px solid rgba(0, 0, 0, 0.14)', pl: 0, pr: 9, pt: 0, position: 'relative', }}>
                                        <form onSubmit={loginForm.handleSubmit} id="frmLogin">
                                            <Box elevation={0}>
                                                <label htmlFor="userid" className="form-label">Email Address*</label>
                                                <input type="text" id="userid" name="userid" autoComplete="off" placeholder="Enter your email address here" className="form-control" value={loginForm.values.userid} onChange={loginForm.handleChange} />
                                                {
                                                    loginForm.touched.userid && loginForm.errors.userid ? (<p className="text-danger">{loginForm.errors.userid}</p>) : null
                                                }
                                            </Box>
                                            <Box elevation={0} sx={{ pt: 2.5 }}>
                                                <label htmlFor="password" className="form-label">Password*</label>
                                                <input type="password" id="password" name="password" autoComplete="off" placeholder="Enter your password here" className="form-control" value={loginForm.values.password} onChange={loginForm.handleChange} />
                                                {
                                                    loginForm.touched.password && loginForm.errors.password ? (<p className="text-danger">{loginForm.errors.password}</p>) : null
                                                }
                                            </Box>
                                            {failedLoginCount > showCaptcha ? (
                                                <Box sx={{ mt: 2 }}>
                                                    <Box className="input-group">
                                                        <Stack direction="row">
                                                            <LoadCanvasTemplate />
                                                            <input placeholder="Captcha Value" id="user_captcha_input" className="form-control" name="user_captcha_input" type="text" ></input>
                                                        </Stack>
                                                    </Box>
                                                </Box>)
                                                : null
                                            }
                                            <Box sx={{ pt: 3.5, pb: 2 }}>
                                                <Button type="submit" variant="contained" size="small" style={{ borderRadius: '20px', padding: '0.4rem 2.6rem', textTransform: 'none'}}>
                                                    Login
                                                </Button>
                                                </Box>
                                                <Box sx={{ pt: 1 }}> 
                                                    <Link href="#" underline="hover" variant="body2" sx={{ mb: 0, fontWeight: 400, color: 'Link.main' }} onClick={(e) => forgetPassword(e)}>Forgot Password?</Link>
                                                </Box>
                                        </form>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Box>
                    <Footer />
                </Paper>
            </Container>
        </React.Fragment>
    );
}